/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Calibri',
  fonts: [
    { src: '/Calibri_Light.ttf' },
    { src: '/Calibri_Bold.ttf', fontWeight: 700 },
    { src: '/Calibri_Regular.ttf', fontWeight: 400 },
  ],
});

const CourrierSalarie = ({ salarie, calcul, entreprise, name, genreSal }) => {
  const { adresse, emploi, categorie, salaire_brut } = salarie;

  const {
    nouveau_coef_salarie,
    nouveau_palier_salarie,
    nouveau_salaire_brut_salarie,
    nouvelle_categorie_salarie,
  } = calcul;
  const {
    dirigeants_entreprise,
    nom_entreprise,
    siege_social_entreprise,
    siret_entreprise,
  } = entreprise;

  const date = new Date();

  return (
    <Document>
      <Page
        style={{
          fontSize: 11,
          fontFamily: 'Calibri',
          paddingTop: 60,
          paddingBottom: 65,
          paddingHorizontal: 70,
          color: 'black',
        }}
      >
        <View>
          <Text>{nom_entreprise}</Text>
          <Text>SIRET : {siret_entreprise}</Text>
          <Text>{siege_social_entreprise}</Text>
          <Text style={{ paddingLeft: 280 }}>
            {genreSal === 'female' ? 'Mme' : 'M.'} {name}
          </Text>
          <Text style={{ paddingLeft: 280 }}>{adresse}</Text>
          <Text style={{ paddingBottom: 55, paddingLeft: 280 }}>
            Fait le {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
          </Text>
          <Text>
            Objet: Nouvelle CCN Agricole{'  - '}Notification de changement de
            classification
          </Text>
          <Text style={{ paddingBottom: 25 }}>
            Lettre remise en main propre contre décharge
          </Text>
          <Text style={{ paddingBottom: 9 }}>
            {genreSal === 'female' ? 'Madame' : 'Monsieur'},
          </Text>
          <Text style={{ textAlign: 'justify', paddingBottom: 9 }}>
            Une nouvelle convention collective nationale entre en application à
            compter du <Text style={{ fontWeight: 700 }}>1er avril 2021</Text>{' '}
            pour l&apos;ensemble des entreprises et exploitations de la
            production agricole et des CUMA.
          </Text>
          <Text style={{ textAlign: 'justify', paddingBottom: 9 }}>
            Cette convention collective définit et instaure notamment un nouveau
            système de classification des emplois et des compétences, unique et
            commun à toutes les entreprises.{' '}
          </Text>
          <Text style={{ textAlign: 'justify', paddingBottom: 9 }}>
            Nous tenons à votre disposition un exemplaire de la convention
            collective si vous souhaitez la consulter.
          </Text>
          <Text style={{ textAlign: 'justify', paddingBottom: 9 }}>
            Dans le cadre de l’entrée en application de cette convention
            collective, nous avons procédé à l&apos;évaluation de votre emploi
            selon les critères définis par cette nouvelle grille de
            classification.
          </Text>
          <Text style={{ textAlign: 'justify', paddingBottom: 9 }}>
            En conséquence, nous vous informons des nouveaux éléments se
            rapportant à votre emploi et qui seront repris sur vos bulletins de
            salaire :
          </Text>
          <Text style={{ paddingLeft: 30 }}>Emploi : {emploi}</Text>
          <Text style={{ paddingLeft: 30 }}>
            Palier : {nouveau_palier_salarie}
          </Text>
          <Text style={{ paddingLeft: 30 }}>
            Coefficient : {nouveau_coef_salarie}
          </Text>
          <Text style={{ paddingBottom: 0, paddingLeft: 30 }}>
            Catégorie socio-professionnelle de l’emploi :{' '}
            {nouvelle_categorie_salarie}
          </Text>
          <View>
            {categorie !== nouvelle_categorie_salarie ? (
              <Text style={{ paddingBottom: 9 }}>
                Nous attirons votre attention sur le fait que ce
                repositionnement conduit à un changement de votre catégorie
                socio-professionnelle, et qu’à ce titre vous serez{' '}
                {genreSal === 'female' ? 'employée' : 'employé'} sous le statut
                de {nouvelle_categorie_salarie}
              </Text>
            ) : (
              <Text> </Text>
            )}
          </View>
          <View>
            {salaire_brut < nouveau_salaire_brut_salarie ? (
              <Text style={{ paddingBottom: 9 }}>
                Par ailleurs, nous vous informons que votre rémunération sera
                établie sur la base d’un nouveau taux horaire brut, réévalué à{' '}
                {nouveau_salaire_brut_salarie} €.
              </Text>
            ) : (
              <Text style={{ paddingBottom: 9 }}>
                Nous vous informons que votre rémunération restera inchangée et
                qu’elle sera toujours établie sur la base d’un taux horaire brut
                fixé à {nouveau_salaire_brut_salarie} €.
              </Text>
            )}
          </View>
          <Text style={{ paddingBottom: 9 }}>
            Cette modification de classification n&apos;a aucun impact sur la
            préservation de vos droits déjà acquis.
          </Text>
          <Text style={{ paddingBottom: 9 }}>
            Restant à votre disposition nous vous prions d’agréer{' '}
            {genreSal === 'female' ? 'Madame' : 'Monsieur'} l’expression de nos
            sincères salutations.
          </Text>
          <Text>{nom_entreprise}</Text>
          <Text style={{ paddingBottom: 120 }}>
            {dirigeants_entreprise}{' '}
            {
              '                                                                                     '
            }
            {genreSal === 'female' ? 'Mme' : 'M.'} {name} *
          </Text>
          <Text style={{ fontSize: 10 }}>
            * Signature précédée de la mention manuscrite « Lettre reçue en main
            propre le … »
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default CourrierSalarie;
