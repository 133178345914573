/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { createContext } from 'react';
import ElementTableList from './ElementTableList';

export const TableContext = createContext();
const Table = ({
  title,
  tableauElements,
  deleteTab,
  genre,
  handleClickEdit,
  handleClickDelete,
  editTab,
  download,
  downloadZip,
  activate = true,
  id,
}) => {
  return (
    <TableContext.Provider value={{ id }}>
      <section>
        <div
          className="infos-entreprise"
          style={{
            opacity: activate ? '1' : '0.4',
            pointerEvents: activate ? 'auto' : 'none',
          }}
        >
          <div className="infos-head">
            <h3 className="nom-entreprise">{title}</h3>
            {download ? (
              <button
                type="button"
                onClick={() => {
                  if (activate) {
                    // eslint-disable-next-line no-console
                    // console.log(title);
                    downloadZip(title, id);
                  }
                }}
                className="download"
              >
                Télécharger tout
              </button>
            ) : (
              <div
                className="icons"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '2.5rem',
                  justifyContent: 'space-between',
                }}
              >
                {editTab ? (
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  <div
                    onClick={() => {
                      handleClickEdit(title, id, tableauElements, genre);
                    }}
                    style={{ placeSelf: 'end', cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon icon={faEdit} color="black" />
                  </div>
                ) : (
                  ''
                )}
                {deleteTab ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <div
                    onClick={() => handleClickDelete(id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon icon={faTrash} color="black" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
          <div className="infos-body">
            <dl>
              <ElementTableList
                title={title}
                download={download}
                list={tableauElements}
              />
            </dl>
          </div>
        </div>
      </section>
    </TableContext.Provider>
  );
};

export default Table;

Table.prototype = {
  title: propTypes.string.isRequired,
  tableauElements: propTypes.object.isRequired,
};

Table.default = {
  deleteTab: false,
  editTab: false,
  download: false,
  activate: true,
};
