/* eslint-disable import/no-cycle */
import Table from './Table';

const TableList = ({ list, download, handleClickEdit, handleClickDelete }) => {
  return list.map((data) => {
    return (
      <Table
        key={data.title}
        title={data.title}
        id={data.id_sal}
        genre={data.genre}
        tableauElements={data.table}
        deleteTab={data.delete}
        editTab={data.edit}
        download={download}
        handleClickDelete={handleClickDelete}
        handleClickEdit={handleClickEdit}
      />
    );
  });
};

export default TableList;
