/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useContext } from 'react';
import { pdf } from '@react-pdf/renderer';
import Table from '../components/Table';
import Synthese from './Synthese';
import { EntrepriseContext } from './Documents';

const Kits = () => {
  const entrepriseContext = useContext(EntrepriseContext);

  const { entreprise, infoSalarie, calculSalarie } = entrepriseContext;

  const kitCC = [
    {
      leftText: 'La convention collective',
      leftTextDetail: ' (à mettre à disposition)',
      download: true,
      type: 'ccn',
    },
    {
      leftText: 'La grille de classification des emplois',
      download: true,
      type: 'classification',
    },
    {
      leftText: 'La grille des positionnements et des salaires',
      download: true,
      type: 'positionnement',
    },
  ];

  const kitEmployeur = [
    {
      leftText:
        'Une synthèse de tous les positionnements de vos salariés « avant / après »',
      download: true,
      type: 'synthese',
    },
    {
      leftText:
        "Note d'information : entrée en application prochaine de la CCN ",
      leftTextDetail: '(à afficher)',
      download: true,
      type: 'note',
    },
    {
      leftText: 'Note information : CCN applicable et disponible ',
      leftTextDetail: '(à afficher)',
      download: true,
      type: 'note-dispo',
    },
  ];

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  async function getProps() {
    const total = [];
    const newTable = () => {
      infoSalarie.salaries.forEach((data) => {
        calculSalarie.forEach((el) => {
          if (data.id === el.id_salarie) {
            total.push({
              categorie: data.categorie,
              coef: data.coef,
              genre: data.genre,
              emploi: data.emploi,
              nom_prenom: data.nom_prenom,
              qualification: data.qualification,
              salaire_brut: data.salaire_brut,
              nouveau_coef_salarie: el.nouveau_coef_salarie,
              nouveau_palier_salarie: el.nouveau_palier_salarie,
              nouveau_salaire_brut_salarie: el.nouveau_salaire_brut_salarie,
              nouvelle_categorie_salarie: el.nouvelle_categorie_salarie,
            });
          }
        });
      });
    };
    newTable();

    await delay(2_000);
    return {
      salarie: total,
      entreprise: entreprise.nom_entreprise,
      dossier: entreprise.num_dossier_entreprise,
    };
  }

  const downloadZip = (title) => {
    if (title === 'Kit   « Convention collective »') {
      const positionnement = async () => {
        let fileData = '';
        await fetch('Grille_des_positionnement_&_des_salaires.pdf', {
          method: 'GET',
        })
          .then((res) => res.blob())
          .then((res) => {
            const file = new Blob([res], {
              type: 'application/pdf;charset=utf-8',
            });
            fileData = file;
          });
        return fileData;
      };

      const classification = async () => {
        let fileData = '';
        await fetch('Grille_de_classification_des_emplois.pdf', {
          method: 'GET',
        })
          .then((res) => res.blob())
          .then((res) => {
            const file = new Blob([res], {
              type: 'application/pdf;charset=utf-8',
            });
            fileData = file;
          });
        return fileData;
      };

      const convention = async () => {
        let fileData = '';
        await fetch('Convention_collective_nationale_production_agricole.pdf', {
          method: 'GET',
        })
          .then((res) => res.blob())
          .then((res) => {
            const file = new Blob([res], {
              type: 'application/pdf;charset=utf-8',
            });
            fileData = file;
          });
        return fileData;
      };

      const zip = new JSZip();

      zip.file(
        'Convention_collective_nationale_production_agricole.pdf',
        convention()
      );
      zip.file('Grille_de_classification_des_emplois.pdf', classification());
      zip.file(
        'Grille_des_positionnement_&_des_salaires.pdf',
        positionnement()
      );

      zip.generateAsync({ type: 'blob' }).then((content) => {
        // see FileSaver.js
        saveAs(content, 'Kit_Convention_collective.zip');
      });
    } else {
      const getSynthese = async () => {
        const props = await getProps();
        const doc = <Synthese {...props} />;
        const asPdf = pdf([]); // {} is important, throws without an argument
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        return blob;
      };

      const note = async () => {
        let fileData = '';
        await fetch('Note_information_1.pdf', {
          method: 'GET',
        })
          .then((res) => res.blob())
          .then((res) => {
            const file = new Blob([res], {
              type: 'application/pdf;charset=utf-8',
            });
            fileData = file;
          });
        return fileData;
      };

      const noteDispo = async () => {
        let fileData = '';
        await fetch('Note_information_2.pdf', {
          method: 'GET',
        })
          .then((res) => res.blob())
          .then((res) => {
            const file = new Blob([res], {
              type: 'application/pdf;charset=utf-8',
            });
            fileData = file;
          });
        return fileData;
      };

      const zip = new JSZip();

      zip.file('Synthese_avant_apres.pdf', getSynthese());
      zip.file('Note_information_application_CCN.pdf', note());
      zip.file('Note_information_CCN_applicable.pdf', noteDispo());

      zip.generateAsync({ type: 'blob' }).then((content) => {
        // see FileSaver.js
        saveAs(content, 'Kit_Employeur.zip');
      });
    }
  };

  return (
    <div className="generaux">
      <Table
        title="Kit &nbsp; « Convention collective »"
        download="true"
        downloadZip={downloadZip}
        tableauElements={kitCC}
      />
      <Table
        title="Kit &nbsp; « Employeur »"
        download="true"
        downloadZip={downloadZip}
        tableauElements={kitEmployeur}
      />
    </div>
  );
};

export default Kits;
