/* eslint-disable react/prop-types */
import { useState } from 'react';
import propTypes from 'prop-types';
import './BlockEmploi.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Popup from './Popup';
import InfoPopup from '../simulateur/InfoPopup';

const BlockEmploi = ({
  categorie,
  qualification,
  coefficient,
  salaire,
  absoluteText,
  infoIcon,
  palier,
}) => {
  const [isshownDoc, setisshownDoc] = useState(false);

  const handleClickDoc = () => {
    setisshownDoc(!isshownDoc);
  };
  return (
    <div className="case">
      <div className="element-case">
        <p className="element-name">Catégorie</p>
        <p className="bold">{categorie}</p>
      </div>
      <div className="element-case">
        <p className="element-name">{palier ? 'Palier' : 'Qualification'}</p>
        <p className="bold">{qualification}</p>
      </div>
      <div className="bottom-case">
        <div className="element-case">
          <p className="element-name">Coefficient</p>
          <p className="bold">{coefficient}</p>
        </div>
        <div className="element-case">
          <p className="element-name">Salaire horaire</p>
          <p className="bold">{salaire}</p>
          {infoIcon === 'all' ? (
            <>
              <FontAwesomeIcon
                className="info-popup-all"
                onClick={() => handleClickDoc()}
                icon={faInfoCircle}
                color="black"
              />
              <Popup
                isShown={isshownDoc}
                closePopup={handleClickDoc}
                addClass="modal-je-decouvre"
              >
                <InfoPopup />
              </Popup>
            </>
          ) : (
            ''
          )}
        </div>
        <p className="alert-salaire">{absoluteText}</p>
      </div>
    </div>
  );
};

export default BlockEmploi;

BlockEmploi.prototype = {
  categorie: propTypes.string.isRequired,
  qualification: propTypes.string.isRequired,
  coefficient: propTypes.string.isRequired,
  salaire: propTypes.string.isRequired,
};
