/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import './WarningPopup.css';

const WarningPopup = ({ closePopup }) => {
  return (
    <div className="alert-popup">
      <h2>Alerte !</h2>
      <p>
        Nous attirons votre attention sur le fait que votre évaluation amène
        votre salarié
        <span className="bold"> à changer de catégorie d’emploi </span>
        (Ouvrier / Technicien / Agent de maitrise / Cadre), ce qui peut avoir
        des conséquences sur le niveau de couverture sociale, les cotisations
        sociales et certains aspects réglementaires.
      </p>
      <p>
        Nous vous invitons à reprendre contact avec votre gestionnaire de paie
        ou votre juriste en droit social.
      </p>
      <div className="buttons-popup">
        <button
          type="button"
          onClick={() => closePopup()}
          rel="nofollow"
          id="cancel"
          className="button"
          href=""
        >
          Je comprends
        </button>
      </div>
    </div>
  );
};

export default WarningPopup;
