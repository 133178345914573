/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import './CalculColumn.css';
import { Markup } from 'interweave';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const CalculColumn = ({
  name,
  subName,
  type,
  tableau,
  isOK,
  showInfoPopup,
  current,
}) => {
  const [currentValueColumn, setcurrentValueColumn] = useState(0);
  const [Background, setBackground] = useState(
    'linear-gradient(to right, #18a096 0%, #18a096 0%, #888888 0%, #888888 100%)'
  );

  useEffect(() => {
    setcurrentValueColumn(current);
    const value = ((current - 0) / (tableau.length - 1 - 0)) * 100;
    setBackground(
      `linear-gradient(to right, #18a096 0%, #18a096 ${value}%, #888888 ${value}%, #888888 100%`
    );
  }, [current]);

  let nbOptions = 0;
  return (
    <div className="column">
      {name !== undefined ? (
        <div className="column-name bold bold-top">
          {name}
          <FontAwesomeIcon
            onClick={() => showInfoPopup(type)}
            className={`info-${type} info-popup`}
            icon={faInfoCircle}
            color="black"
          />
          {subName ? <p className="sub-column-name">{subName}</p> : ''}
        </div>
      ) : (
        ''
      )}
      {tableau !== undefined ? (
        <div className="div-input">
          <datalist id="custom-list">
            {tableau.map(() => {
              // eslint-disable-next-line no-plusplus
              nbOptions++;
              return (
                <option key={nbOptions} value={nbOptions}>
                  {' '}
                </option>
              );
            })}
          </datalist>

          <p className="valeur-column">{currentValueColumn}</p>
          <input
            onInput={(event) => {
              const value =
                ((event.target.value - event.target.min) /
                  (event.target.max - event.target.min)) *
                100;
              setBackground(
                `linear-gradient(to right, #18a096 0%, #18a096 ${value}%, #888888 ${value}%, #888888 100%`
              );
              isOK(event.target.value);
              setcurrentValueColumn(event.target.value);
            }}
            style={{ background: Background }}
            type="range"
            list="custom-list"
            min="0"
            max={tableau.length - 1}
            value={currentValueColumn}
          />
        </div>
      ) : (
        ''
      )}
      {tableau !== undefined ? (
        <div className={`${type}-description description-case case`}>
          <Markup content={tableau[currentValueColumn][0]} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CalculColumn;
