import { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import BackOffice from './BackOffice';
import './Consommation.css';

const Consommation = () => {
  const [connect, setConnect] = useState(false);
  const [errorText, seterrorTex] = useState('');
  const [listCode, setListCode] = useState([]);
  const [JWTBackoffice, setJWTBackoffice] = useState('');
  const [RightBackoffice, setRightBackoffice] = useState(0);
  const [connexionDisabled, setConnexionDisabled] = useState(true);
  const [Login, setLogin] = useState('');
  const [formVal, setFormVal] = useState({ login: '', password: '' });

  const handleChange = (event) => {
    const { value } = event.target;
    setFormVal({
      ...formVal,
      [event.target.name]: value,
    });
  };

  function onChange(value) {
    const form = new FormData();
    form.append('response', value);

    fetch(`${process.env.REACT_APP_URL}backoffice/api/1.0/verifyCaptcha`, {
      method: 'POST',
      body: form,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          setConnexionDisabled(false);
        } else {
          setConnexionDisabled(true);
        }
      });

    if (value === null) {
      setConnexionDisabled(true);
    } else {
      setConnexionDisabled(false);
    }
  }

  const handleClickSubmit = async () => {
    const form = new FormData();
    form.append('login', formVal.login);
    form.append('pass', formVal.password);

    await fetch(`${process.env.REACT_APP_URL}backoffice/api/1.0/login`, {
      method: 'POST',
      body: form,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.token) {
          setConnect(true);
          setJWTBackoffice(res.token);
          setRightBackoffice(res.right);
          setLogin(res.login);
          const code = JSON.parse(res.code);
          setListCode(code.codes);
        } else if (res === false) {
          seterrorTex('Identifiant ou mot de passe incorrect');
        }
      })
      .catch((err) => err);
  };

  return (
    <>
      {connect ? (
        <BackOffice
          jwt={JWTBackoffice}
          right={RightBackoffice}
          login={Login}
          listCode={listCode}
        />
      ) : (
        <div className="login-page">
          <div className="div-formulaire">
            <h1>Connexion</h1>
            <h4>Backoffice du module CCNA</h4>
            <form className="form-login" noValidate>
              <TextField
                name="login"
                className="input-login"
                label="Identifiant"
                value={formVal.login}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                name="password"
                type="password"
                className="input-login"
                label="Mot de passe"
                value={formVal.password}
                variant="outlined"
                onChange={handleChange}
                helperText={errorText}
              />
              <ReCAPTCHA
                style={{ marginTop: '1.5em' }}
                sitekey="6Ld5gaYaAAAAAKA6WMlxjafky5Lwhvkkrp1yFOBY"
                onChange={onChange}
              />
              {connexionDisabled ? (
                <>
                  <Button
                    className="button-login"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled
                  >
                    Se connecter
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="button-login"
                    variant="contained"
                    color="primary"
                    onClick={handleClickSubmit}
                    disableElevation
                  >
                    Se connecter
                  </Button>
                </>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Consommation;
