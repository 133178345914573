/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { pdf } from '@react-pdf/renderer';
import { useContext } from 'react';
import { saveAs } from 'file-saver';
import Synthese from './Synthese';
import { EntrepriseContext } from './Documents';

// eslint-disable-next-line react/prop-types
const DownloadSynthese = () => {
  const entrepriseContext = useContext(EntrepriseContext);

  const { entreprise, infoSalarie, calculSalarie } = entrepriseContext;

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  async function getProps() {
    const total = [];
    const newTable = () => {
      infoSalarie.salaries.forEach((data) => {
        calculSalarie.forEach((el) => {
          if (data.id === el.id_salarie) {
            total.push({
              categorie: data.categorie,
              coef: data.coef,
              emploi: data.emploi,
              genre: data.genre,
              nom_prenom: data.nom_prenom,
              qualification: data.qualification,
              salaire_brut: data.salaire_brut,
              nouveau_coef_salarie: el.nouveau_coef_salarie,
              nouveau_palier_salarie: el.nouveau_palier_salarie,
              nouveau_salaire_brut_salarie: el.nouveau_salaire_brut_salarie,
              nouvelle_categorie_salarie: el.nouvelle_categorie_salarie,
            });
          }
        });
      });
    };
    newTable();

    await delay(2_000);
    return {
      salarie: total,
      entreprise: entreprise.nom_entreprise,
      dossier: entreprise.num_dossier_entreprise,
    };
  }

  return (
    <FontAwesomeIcon
      style={{ cursor: 'pointer' }}
      icon={faDownload}
      onClick={async () => {
        const props = await getProps();
        const doc = <Synthese {...props} />;
        const asPdf = pdf([]); // {} is important, throws without an argument
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, `Synthese_avant_apres.pdf`);
      }}
      color="black"
    />
  );
};

export default DownloadSynthese;
