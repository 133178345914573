/* eslint-disable react/prop-types */
/* eslint-disable no-inner-declarations */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import gender from 'gender-detection';
import Synthese from './Synthese';
import { JWTContext } from '../App';
import { EntrepriseContext } from './Documents';
import CourrierSalarie from './CourrierSalarie';
import Avenant from './Avenant';
import './BoutonsGlobaux.css';

const BoutonsGlobaux = ({ handleClickEmail }) => {
  const context = useContext(JWTContext);
  const { JWTString } = context;
  const entrepriseContext = useContext(EntrepriseContext);

  const { entreprise } = entrepriseContext;

  const [chargement, setchargement] = useState(false);
  const [resultatSalaries, setresultatSalaries] = useState([]);
  const [infoSalarie, setinfoSalarie] = useState([]);
  const [calculSalarie, setcalculSalarie] = useState([]);
  const [dataEntreprise, setdataEntreprise] = useState({});

  useEffect(() => {
    const getCalculsSalaries = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/salaries`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then(async (res) => {
          setinfoSalarie(res);
          setresultatSalaries([]);
          const tabResult = [];
          const tabCalcul = [];
          res.salaries.forEach((element) => {
            const tabAttente = {
              title: element.nom_prenom,
              id: element.id,
              delete: true,
              activate: false,
              table: [],
            };

            const form = new FormData();
            form.append('id', element.id);

            fetch(`${process.env.REACT_APP_URL}back/api/1.0/calculSal`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                Authorization: `Bearer ${JWTString}`,
              },
              body: form,
            })
              .then((data) => data.json())
              .then((data) => {
                if (data) {
                  tabAttente.activate = true;
                }
                tabCalcul.push(data);
                tabResult.push(tabAttente);
                setcalculSalarie(calculSalarie.concat(tabCalcul));
                setresultatSalaries(resultatSalaries.concat(tabResult));
              });
          });
        });
    };

    getCalculsSalaries();
  }, []);

  useEffect(() => {
    setdataEntreprise(entreprise);
  }, [entreprise]);

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  const downloadAll = () => {
    setchargement(true);
    const zip = new JSZip();

    let time = 500;
    calculSalarie.forEach((data) => {
      if (data) {
        let calcul = {};
        let salarie = {};
        let title = '';
        const id = data.id_salarie;

        calculSalarie.forEach((element) => {
          if (element.id_salarie === id) {
            calcul = element;
          }
        });
        infoSalarie.salaries.forEach((element) => {
          if (element.id === id) {
            salarie = element;
            title = element.nom_prenom;
          }
        });
        let genre = 'male';
        if (salarie.genre === '' && salarie.nom_prenom.split(' ').length > 1) {
          genre = gender.detect(salarie.nom_prenom.split(' ').slice(-1)[0]);
        }

        if (salarie.genre === 'MME') {
          genre = 'female';
        } else if (salarie.genre === 'M') {
          genre = 'male';
        }
        const split = salarie.nom_prenom.split(' ');
        let nomSal = '';
        if (split.length === 2) {
          nomSal = `${split[1]} ${split[0]}`;
        } else if (split.length === 3) {
          nomSal = `${split[2]} ${split[0]} ${split[1]}`;
        } else {
          nomSal = salarie.nom_prenom;
        }

        async function getProps() {
          await delay(time);
          return {
            salarie: salarie,
            calcul: calcul,
            name: nomSal,
            entreprise: dataEntreprise,
            genreSal: genre,
          };
        }

        async function getPropsCourrier() {
          await delay(time + 1000);
          return {
            salarie: salarie,
            calcul: calcul,
            name: nomSal,
            entreprise: dataEntreprise,
            genreSal: genre,
          };
        }

        const avenant = async () => {
          const props = await getProps();
          const doc = <Avenant {...props} />;
          const asPdf = pdf([]); // {} is important, throws without an argument
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        };

        const courrier = async () => {
          const props = await getPropsCourrier();
          const doc = <CourrierSalarie {...props} />;
          const asPdf = pdf([]); // {} is important, throws without an argument
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          return blob;
        };

        const ficheSynthese = async () => {
          let fileData = '';
          const form = new FormData();
          form.append(
            'name',
            `${title.split(' ').join('_')}_nouveau_positionnement.pdf`
          );

          await fetch(`${process.env.REACT_APP_URL}back/api/1.0/uploadedFile`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${JWTString}`,
            },
            body: form,
          })
            .then((res) => res.blob())
            .then((res) => {
              const file = new Blob([res], {
                type: 'application/pdf;charset=utf-8',
              });
              fileData = file;
            })
            .catch((err) => err);

          return fileData;
        };

        const folder = zip.folder(title.split(' ').join('_'));
        folder.file(
          `${title.split(' ').join('_')}_avenant_CCNA.pdf`,
          avenant()
        );
        folder.file(
          `${title.split(' ').join('_')}_courrier_information.pdf`,
          courrier()
        );
        folder.file(
          `${title.split(' ').join('_')}_nouveau_positionnement.pdf`,
          ficheSynthese()
        );
        time += 1000;
      }
    });

    async function getPropsSynthese() {
      const total = [];
      const newTable = () => {
        infoSalarie.salaries.forEach((dataEl) => {
          calculSalarie.forEach((el) => {
            if (dataEl.id === el.id_salarie) {
              total.push({
                categorie: dataEl.categorie,
                coef: dataEl.coef,
                genre: dataEl.genre,
                emploi: dataEl.emploi,
                nom_prenom: dataEl.nom_prenom,
                qualification: dataEl.qualification,
                salaire_brut: dataEl.salaire_brut,
                nouveau_coef_salarie: el.nouveau_coef_salarie,
                nouveau_palier_salarie: el.nouveau_palier_salarie,
                nouveau_salaire_brut_salarie: el.nouveau_salaire_brut_salarie,
                nouvelle_categorie_salarie: el.nouvelle_categorie_salarie,
              });
            }
          });
        });
      };
      newTable();

      await delay(time + 2_000);
      return {
        salarie: total,
        entreprise: entreprise.nom_entreprise,
        dossier: entreprise.num_dossier_entreprise,
      };
    }

    const getSynthese = async () => {
      const props = await getPropsSynthese();
      const doc = <Synthese {...props} />;
      const asPdf = pdf([]); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      return blob;
    };

    const note = async () => {
      let fileData = '';
      await fetch('Note_information_1.pdf', {
        method: 'GET',
      })
        .then((res) => res.blob())
        .then((res) => {
          const file = new Blob([res], {
            type: 'application/pdf;charset=utf-8',
          });
          fileData = file;
        });
      return fileData;
    };

    const noteDispo = async () => {
      let fileData = '';
      await fetch('Note_information_2.pdf', {
        method: 'GET',
      })
        .then((res) => res.blob())
        .then((res) => {
          const file = new Blob([res], {
            type: 'application/pdf;charset=utf-8',
          });
          fileData = file;
        });
      return fileData;
    };

    const employeur = zip.folder('Kit_Employeur');
    employeur.file('Synthese_avant_apres.pdf', getSynthese());
    employeur.file('Note_information_application_CCN.pdf', note());
    employeur.file('Note_information_CCN_applicable.pdf', noteDispo());

    const positionnement = async () => {
      let fileData = '';
      await fetch('Grille_des_positionnement_&_des_salaires.pdf', {
        method: 'GET',
      })
        .then((res) => res.blob())
        .then((res) => {
          const file = new Blob([res], {
            type: 'application/pdf;charset=utf-8',
          });
          fileData = file;
        });
      return fileData;
    };

    const classification = async () => {
      let fileData = '';
      await fetch('Grille_de_classification_des_emplois.pdf', {
        method: 'GET',
      })
        .then((res) => res.blob())
        .then((res) => {
          const file = new Blob([res], {
            type: 'application/pdf;charset=utf-8',
          });
          fileData = file;
        });
      return fileData;
    };

    const convention = async () => {
      let fileData = '';
      await fetch('Convention_collective_nationale_production_agricole.pdf', {
        method: 'GET',
      })
        .then((res) => res.blob())
        .then((res) => {
          const file = new Blob([res], {
            type: 'application/pdf;charset=utf-8',
          });
          fileData = file;
        });
      return fileData;
    };

    const conventionCollective = zip.folder('Kit_Convention_Collective');
    conventionCollective.file(
      'Convention_collective_nationale_production_agricole.pdf',
      convention()
    );
    conventionCollective.file(
      'Grille_de_classification_des_emplois.pdf',
      classification()
    );
    conventionCollective.file(
      'Grille_des_positionnement_&_des_salaires.pdf',
      positionnement()
    );

    zip.generateAsync({ type: 'blob' }).then((content) => {
      // see FileSaver.js
      saveAs(content, `Documents_nouvelle_convention_agricole.zip`);
      setchargement(false);
    });
  };

  return (
    <div className="boutons-glob">
      <div
        style={{
          position: 'fixed',
          top: '0',
          width: '100%',
          display: chargement ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontFamily: 'Nexa-bold',
          fontSize: '1.5em',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          height: '100%',
        }}
      >
        <img src="img/loader.gif" alt="chargement" />
        Téléchargement en cours ...
      </div>
      <button
        type="button"
        onClick={() => {
          downloadAll();
        }}
        className="telecharger-tout-glob"
      >
        Télécharger tous les documents
      </button>
      {entreprise.gestionnaire_entreprise !== '' ? (
        <button
          type="button"
          onClick={() => handleClickEmail()}
          className="gestionnaire-glob"
        >
          Envoyer l&apos;évaluation à votre gestionnaire de paye
        </button>
      ) : (
        <button
          type="button"
          style={{ pointerEvents: 'none', opacity: 0.3 }}
          className="gestionnaire-glob"
        >
          Envoyer l&apos;évaluation à votre gestionnaire de paye
        </button>
      )}
    </div>
  );
};

export default BoutonsGlobaux;
