/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useState, useEffect, useContext } from 'react';
import { JWTContext } from '../App';
import './PopupAjout.css';

const PopupAjout = ({ title, inputValues, ajout, close }) => {
  const [salarie, setsalarie] = useState({});
  const context = useContext(JWTContext);
  const { JWTString } = context;

  const handleChange = (event) => {
    const { value } = event.target;
    setsalarie({
      ...salarie,
      [event.target.name]: value,
    });
  };

  useEffect(() => {
    if (inputValues) {
      setsalarie(inputValues);
    }
  }, [inputValues]);

  const ajoutSalarie = async () => {
    const form = new FormData();
    form.append('genre', salarie.genre);
    form.append('adresse', salarie.adresse);
    form.append('categorie', salarie.categorie);
    form.append('coef', salarie.coef);
    form.append('emploi', salarie.emploi);
    form.append('nationalite', salarie.nationalite);
    form.append('nom', salarie.nom);
    form.append('qualif', salarie.qualif);
    form.append('salaire', salarie.salaire.replace(/,/g, '.'));
    form.append('ss', salarie.ss);

    fetch(`${process.env.REACT_APP_URL}back/api/1.0/salaries`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JWTString}`,
      },
      body: form,
    }).then((res) => res.text());
  };

  const updateSalarie = () => {
    const form = new FormData();
    form.append('id', salarie.id);
    form.append('genre', salarie.genre);
    form.append('adresse', salarie.adresse);
    form.append('categorie', salarie.categorie);
    form.append('coef', salarie.coef);
    form.append('emploi', salarie.emploi);
    form.append('nationalite', salarie.nationalite);
    form.append('nom', salarie.nom);
    form.append('qualif', salarie.qualif);
    form.append('salaire', salarie.salaire.replace(/,/g, '.'));
    form.append('ss', salarie.ss);

    fetch(`${process.env.REACT_APP_URL}back/api/1.0/salariesMod`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JWTString}`,
      },
      body: form,
    }).then((res) => res.text());
  };

  const handleSubmit = (event) => {
    if (ajout) {
      ajoutSalarie();
    } else {
      updateSalarie();
    }
    close();
    event.preventDefault();
  };

  return (
    <>
      <div className="title-form">
        <h3>{title}</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="genre">
          Genre :
          <div style={{ display: 'flex' }}>
            <label style={{ display: 'flex' }} htmlFor="m">
              <input
                required
                id="m"
                type="radio"
                value="M"
                name="genre"
                checked={salarie.genre === 'M'}
                onChange={handleChange}
              />
              M.
            </label>
            <label style={{ display: 'flex' }} htmlFor="mme">
              <input
                type="radio"
                id="mme"
                name="genre"
                value="MME"
                checked={salarie.genre === 'MME'}
                onChange={handleChange}
              />
              MME
            </label>
          </div>
        </label>
        <label htmlFor="nom">
          Nom Prénom :
          <input
            required
            id="nom"
            type="text"
            value={salarie.nom}
            name="nom"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="adresse">
          Adresse :
          <input
            required
            id="adresse"
            type="text"
            value={salarie.adresse}
            name="adresse"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="nationalite">
          Nationalite :
          <input
            required
            id="nationalite"
            type="text"
            value={salarie.nationalite}
            name="nationalite"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="ss">
          N°Sécurité Sociale :
          <input
            required
            id="ss"
            type="text"
            value={salarie.ss}
            name="ss"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="emploi">
          Emploi :
          <input
            required
            id="emploi"
            type="text"
            value={salarie.emploi}
            name="emploi"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="categorie">
          Categorie :
          <input
            required
            id="categorie"
            type="text"
            value={salarie.categorie}
            name="categorie"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="qualif">
          Qualification :
          <input
            required
            id="qualif"
            type="text"
            value={salarie.qualif}
            name="qualif"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="salaire">
          Salaire horaire brut :
          <input
            required
            id="salaire"
            type="text"
            value={salarie.salaire}
            name="salaire"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="coef">
          Coefficient :
          <input
            required
            id="coef"
            type="text"
            value={salarie.coef}
            name="coef"
            onChange={handleChange}
          />
        </label>
        <input type="submit" value={title} />
      </form>
    </>
  );
};

export default PopupAjout;
