/* eslint-disable react/prop-types */
import BlockEmploi from '../components/BlockEmploi';
import DivPositionnement from '../components/DivPositionnement';
import './BlockHead.css';

const BlockHead = ({
  type,
  textTop,
  tableau,
  absoluteText,
  infoIcon,
  palier,
}) => {
  return (
    <div className="block-head">
      <DivPositionnement bold classtoAdd={type} text={textTop} />
      {tableau ? (
        <BlockEmploi
          key={tableau.categorie}
          categorie={tableau.categorie}
          qualification={tableau.qualification}
          coefficient={tableau.coefficient}
          salaire={`${tableau.salaire} €`}
          absoluteText={absoluteText}
          infoIcon={infoIcon}
          palier={palier}
        />
      ) : (
        <BlockEmploi
          key=""
          categorie=""
          qualification=""
          coefficient=""
          salaire=""
          palier={palier}
        />
      )}
    </div>
  );
};

export default BlockHead;
