/* eslint-disable object-shorthand */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-undef */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import propTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { JWTContext } from '../App';
import BlockEmploi from '../components/BlockEmploi';
import './LineEmploi.css';

const LineEmploi = ({ name, id, actuel, emploiSal }) => {
  const context = useContext(JWTContext);
  const { JWTString } = context;

  const categorie = actuel[4].rightText;
  const qualification = actuel[5].rightText;
  const coefficient = actuel[7].rightText;
  const salaire = actuel[6].rightText;
  const [positionOK, setpositionOK] = useState({
    categorie: '',
    qualification: '',
    coefficient: '',
    salaire: '',
  });

  useEffect(() => {
    const getCalcul = async () => {
      const form = new FormData();
      form.append('id', id);

      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/calculSal`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
        body: form,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.nouvelle_categorie_salarie !== null) {
            setpositionOK({
              categorie: res.nouvelle_categorie_salarie,
              qualification: res.nouveau_palier_salarie,
              coefficient: res.nouveau_coef_salarie,
              salaire: res.nouveau_salaire_brut_salarie,
            });
          }
        });
    };

    getCalcul();
  }, []);

  // fetch si le nouveau positionnement existe pour ce salarie
  // si existe pas alors retourner le bouton

  const { push } = useHistory();
  return (
    <div className="emploi">
      <div className="person">
        <h3 className="bold">{name[0]}</h3>
        <p>{name[1]}</p>
      </div>
      <BlockEmploi
        key={actuel[2].rightText}
        categorie={categorie}
        qualification={qualification}
        coefficient={coefficient}
        salaire={`${salaire} €`}
      />
      {positionOK.categorie !== '' ? (
        <>
          <BlockEmploi
            key={actuel[2].rightText}
            categorie={positionOK.categorie}
            qualification={positionOK.qualification}
            coefficient={positionOK.coefficient}
            salaire={`${positionOK.salaire} €`}
            palier="true"
          />
          <div className="validation">
            <button
              type="button"
              onClick={() =>
                push({
                  pathname: '/simulateur',
                  idsal: id,
                })
              }
              className="recommencer-emploi"
            >
              Recommencer
            </button>
          </div>
        </>
      ) : (
        <>
          <div
            className="case case-empty"
            onClick={() =>
              push({
                pathname: '/simulateur',
                idsal: id,
                emploi: emploiSal,
              })
            }
            role="button"
          >
            <img src="img/start.svg" alt="icon start" width="50" height="50" />
            <h2>Démarrer le positionnement</h2>
          </div>
          <div className="validation"> </div>
        </>
      )}
    </div>
  );
};

export default LineEmploi;

LineEmploi.prototype = {
  name: propTypes.array.isRequired,
  actuel: propTypes.arrayOf(
    propTypes.shape({
      leftText: propTypes.string.isRequired,
      rightText: propTypes.string.isRequired,
      type: propTypes.string.isRequired,
    })
  ).isRequired,
};
