/* eslint-disable prefer-template */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import { useContext, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { SimulateurContext } from './Simulateur';
import './ButtonsCalcul.css';
import { JWTContext } from '../App';

const ButtonsCalcul = ({ tableau, startCalcul, showPopup, newCalcul }) => {
  const context = useContext(JWTContext);
  const { JWTString } = context;

  const simulateurContext = useContext(SimulateurContext);
  const [activeButton, setactiveButton] = useState('not-active');
  const [activeButtonCalculFait, setactiveButtonCalculFait] = useState(
    'not-active'
  );
  const [CalculFait, setCalculFait] = useState(false);
  const { CalculOK } = simulateurContext;
  const { push } = useHistory();

  const addCalcul = async () => {
    const form = new FormData();
    form.append('categorie', newCalcul.categorie);
    form.append('palier', newCalcul.qualification);
    form.append('id', newCalcul.id);
    form.append('coef', newCalcul.coefficient);
    form.append('salaire', newCalcul.salaire);
    form.append('calcul', JSON.stringify(CalculOK));

    await fetch(`${process.env.REACT_APP_URL}back/api/1.0/calcul`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${JWTString}`,
      },
      body: form,
    }).then((res) => res.text());
  };

  useEffect(() => {
    if (CalculOK.length === 6) {
      setactiveButton('active');
      if (CalculFait) {
        setactiveButtonCalculFait('active');
      } else {
        setactiveButtonCalculFait('not-active');
      }
    } else {
      setactiveButton('not-active');
      setactiveButtonCalculFait('not-active');
    }
  }, [CalculOK, CalculFait]);
  return (
    <div data-html2canvas-ignore="true" className="buttons-calcul">
      <button
        type="button"
        className={activeButton}
        onClick={() => {
          startCalcul();
          setCalculFait(true);
        }}
        id="calculer"
      >
        Calculer
      </button>
      <button
        type="button"
        onClick={() => showPopup()}
        className={activeButtonCalculFait}
        id="simuler"
      >
        Simuler le coût
      </button>
      <button
        type="button"
        onClick={() => {
          addCalcul();
          push('/emploi');
          const divToDisplay = document.getElementById('div');
          const datetime = document.createElement('p');
          // eslint-disable-next-line prettier/prettier
          const today = new Date();
          const date =
            today.getFullYear() +
            '-' +
            (today.getMonth() + 1) +
            '-' +
            today.getDate();
          const time =
            today.getHours() +
            ':' +
            today.getMinutes() +
            ':' +
            today.getSeconds();
          datetime.innerHTML = date + ' ' + time;
          datetime.style.color = 'white';
          datetime.style.position = 'absolute';
          datetime.style.top = '0';
          datetime.style.right = '20px';
          divToDisplay.append(datetime);
          html2canvas(divToDisplay).then((canvas) => {
            const divImage = canvas.toDataURL('image/jpeg', 0.8);
            // eslint-disable-next-line new-cap
            const pdf = new jsPDF('l', 'mm', [canvas.width, canvas.height]);
            pdf.addImage(divImage, 'jpeg', 10, 10, canvas.width, canvas.height);

            const form = new FormData();
            form.append(
              'pdf',
              new Blob([pdf.output('blob')], { type: 'application/pdf' })
            );
            form.append(
              'name',
              `${tableau.name.split(' ').join('_')}_nouveau_positionnement.pdf`
            );

            fetch(`${process.env.REACT_APP_URL}back/api/1.0/upload`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                Authorization: `Bearer ${JWTString}`,
              },
              body: form,
            })
              .then((res) => res.text())
              .then((res) => res);
          });
        }}
        className={activeButtonCalculFait}
        id="valider"
      >
        Valider
      </button>
    </div>
  );
};

export default ButtonsCalcul;
