/* eslint-disable import/no-cycle */
import LineEmploi from './LineEmploi';

const LineEmploiList = ({ salarie }) => {
  return salarie.map((data) => {
    const name = [data.title, data.table[3].rightText];
    return (
      <LineEmploi
        key={data.title}
        name={name}
        id={data.id_sal}
        emploiSal={data.emploi}
        actuel={data.table}
      />
    );
  });
};

export default LineEmploiList;
