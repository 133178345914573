/* eslint-disable import/no-cycle */
import { useContext } from 'react';
import { JWTContext } from '../App';

/* eslint-disable react/prop-types */
const PopupDelete = ({ handleClickDelete, idToDelete }) => {
  const context = useContext(JWTContext);
  const { JWTString } = context;

  const DeleteSalarie = (id) => {
    const form = new FormData();
    form.append('id', id);
    fetch(`${process.env.REACT_APP_URL}back/api/1.0/salariesd`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${JWTString}`,
      },
      body: form,
    }).then((res) => res.text());
  };
  return (
    <>
      <h2>Êtes-vous sur de vouloir supprimer ce salarié ?</h2>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          onClick={() => handleClickDelete()}
          style={{
            boxShadow:
              'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
            display: 'flex',
            alignItems: 'center',
            height: '80%',
            justifyContent: 'center',
            padding: '0.75rem 2rem',
            border: 'solid 1px transparent',
            borderRadius: '0.25rem',
            color: 'black',
            backgroundColor: 'white',
            textDecoration: 'none',
            fontFamily: 'Nexa-Light',
          }}
          type="button"
        >
          Annuler
        </button>
        <button
          onClick={() => {
            handleClickDelete();
            DeleteSalarie(idToDelete);
          }}
          style={{
            boxShadow:
              'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
            display: 'flex',
            alignItems: 'center',
            height: '80%',
            justifyContent: 'center',
            padding: '0.75rem 2rem',
            border: 'solid 1px transparent',
            borderRadius: '0.25rem',
            color: 'white',
            backgroundColor: '#18a096',
            textDecoration: 'none',
            fontFamily: 'Nexa-light',
            marginLeft: '1em',
          }}
          type="button"
        >
          Confirmer la suppression
        </button>
      </div>
    </>
  );
};

export default PopupDelete;
