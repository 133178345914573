/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import './Redirection.css';

const Redirection = ({ JWTString }) => {
  const [NumeroAssistance, setNumeroAssistance] = useState(false);
  const [NumeroDossier, setNumeroDossier] = useState('');
  useEffect(() => {
    const accompagnementFunc = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/modulable/numero`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setNumeroAssistance(res.numero_tel_hotline);
          setNumeroDossier(res.dossier);
        });
    };

    accompagnementFunc();
  }, []);

  return (
    <>
      <div
        className="div-redirection"
        style={{
          backgroundColor: 'white',
          padding: '2em',
          fontFamily: 'Nexa-bold',
          boxShadow: '0px 2px 3px lightgray',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <p>Bonjour,</p>
        <p>
          Le numéro de dossier de votre entreprise (&nbsp;{NumeroDossier}&nbsp;)
          n’est pas référencé dans la plateforme.{' '}
        </p>
        {NumeroAssistance ? (
          <p>
            Nous vous invitons à contacter le &nbsp;&nbsp;&nbsp;
            {NumeroAssistance} &nbsp;&nbsp;&nbsp;pour plus de renseignements.
          </p>
        ) : (
          <p>
            {' '}
            Nous vous invitons à vous rapprocher de votre interlocuteur
            Cerfrance pour plus de renseignements.
          </p>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1em',
          }}
        >
          <img
            alt="logo cerfrance"
            src="img/LOGO-CERFRANCE.png"
            style={{ width: '20em' }}
          />
        </div>
      </div>
    </>
  );
};

export default Redirection;
