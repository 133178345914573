/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-extend-native */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import { useRef, useEffect, useContext, useState } from 'react';
import Chart from 'chart.js';
import './Graphique.css';
import { DataContext } from './BackOffice';

const Graphique = ({ tab }) => {
  const ctx = useRef();
  const context = useContext(DataContext);
  const [entrepriseGraph, setentrepriseGraph] = useState([]);
  const [salarieGraph, setsalarieGraph] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [demarrageGraph, setdemarrageGraph] = useState(false);
  const [myChart, setmyChart] = useState(false);
  const [creationDuGraph, setcreationDuGraph] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const { selectedDate, jwt, Code } = context;

  const getDaysArray = (start, end) => {
    const arr = [];

    const dt = new Date(start);
    let nbDays = (end - start) / (1000 * 3600 * 24);

    arr.push(new Date(dt));
    while (nbDays > 0) {
      dt.setDate(dt.getDate() + 1);
      arr.push(new Date(dt));
      nbDays--;
    }
    return arr;
  };

  function getYYYYMMDD(d0) {
    const d = new Date(d0);
    return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split('T')[0];
  }

  useEffect(() => {
    let dates = getDaysArray(
      new Date(selectedDate.debut),
      new Date(selectedDate.fin)
    );

    const datesEN = dates.map((v) => {
      return getYYYYMMDD(v);
    });

    dates = datesEN.map((v) => {
      const res = v.split('-');
      return `${res[2]}-${res[1]}-${res[0]}`;
    });

    const fetchEvalue = async () => {
      const form = new FormData();
      form.append('dates', JSON.stringify(datesEN));
      form.append('code', Code);

      await fetch(`${process.env.REACT_APP_URL}backoffice/api/1.0/evalue`, {
        method: 'POST',
        body: form,
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setsalarieGraph(res.salarie);
          setentrepriseGraph(res.entreprise);
          setdemarrageGraph(true);
        })
        .catch((err) => err);
    };

    const fetchRestant = async () => {
      const form = new FormData();
      form.append('dates', JSON.stringify(datesEN));
      form.append('code', Code);

      await fetch(`${process.env.REACT_APP_URL}backoffice/api/1.0/restant`, {
        method: 'POST',
        body: form,
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setsalarieGraph(res.salarie);
          setentrepriseGraph(res.entreprise);
        })
        .catch((err) => err);
    };

    if (jwt !== '') {
      if (tab === 'evalue') {
        fetchEvalue();
      } else if (tab === 'restant') {
        fetchRestant();
      }
    }
  }, [ctx, selectedDate, tab, Code]);

  useEffect(() => {
    let dates = getDaysArray(
      new Date(selectedDate.debut),
      new Date(selectedDate.fin)
    );

    const datesEN = dates.map((v) => {
      return getYYYYMMDD(v);
    });

    dates = datesEN.map((v) => {
      const res = v.split('-');
      return `${res[2]}-${res[1]}-${res[0]}`;
    });

    if (
      !demarrageGraph &&
      !creationDuGraph &&
      entrepriseGraph.length > 0 &&
      salarieGraph.length > 0
    ) {
      setmyChart(
        new Chart(ctx.current, {
          type: 'line',
          data: {
            labels: dates,
            datasets: [
              {
                fill: false,
                label:
                  tab === 'restant'
                    ? "Entreprises n'ayant pas démarré le positionnement"
                    : 'Entreprises ayant validé les CGV/RGPD/Tarifs',
                backgroundColor: '#3f51b5',
                borderColor: '#3f51b5',
                data: entrepriseGraph,
                fontSize: 25,
              },
              {
                fill: false,
                label:
                  tab === 'restant'
                    ? 'Salariés ajoutés mais non positionnés'
                    : 'Salariés positionnés',
                backgroundColor: '#18a096',
                borderColor: '#18a096',
                data: salarieGraph,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRation: false,
            legend: {
              labels: {
                fontSize: 15,
                fontFamily: 'Nexa-bold',
              },
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    fontSize: 16,
                    fontFamily: 'Nexa-bold',
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    fontSize: 15,
                    fontFamily: 'Nexa-Light',
                  },
                },
              ],
            },
            tooltips: {
              titleFontSize: 20,
              bodyFontSize: 18,
            },
          },
        })
      );
      setcreationDuGraph(true);
    } else if (demarrageGraph && creationDuGraph) {
      myChart.destroy();
      setmyChart(
        new Chart(ctx.current, {
          type: 'line',
          data: {
            labels: dates,
            datasets: [
              {
                fill: false,
                label:
                  tab === 'restant'
                    ? "Entreprises n'ayant pas démarré le positionnement"
                    : 'Entreprises ayant validé les CGV/RGPD/Tarifs',
                backgroundColor: '#3f51b5',
                borderColor: '#3f51b5',
                data: entrepriseGraph,
                fontSize: 25,
              },
              {
                fill: false,
                label:
                  tab === 'restant'
                    ? 'Salariés ajoutés mais non positionnés'
                    : 'Salariés positionnés',
                backgroundColor: '#18a096',
                borderColor: '#18a096',
                data: salarieGraph,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRation: false,
            legend: {
              labels: {
                fontSize: 15,
                fontFamily: 'Nexa-bold',
              },
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    fontSize: 16,
                    fontFamily: 'Nexa-bold',
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    fontSize: 15,
                    fontFamily: 'Nexa-Light',
                  },
                },
              ],
            },
            tooltips: {
              titleFontSize: 20,
              bodyFontSize: 18,
            },
          },
        })
      );
    }
  }, [salarieGraph, entrepriseGraph, width]);

  return (
    <canvas ref={ctx} id="myChart">
      {' '}
    </canvas>
  );
};

export default Graphique;
