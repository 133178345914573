/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/void-dom-elements-no-children */
import { useState } from 'react';
import Select from 'react-select';
import './SimulationPopup.css';
import BlockHead from './BlockHead';
import CoutGlobal from './CoutGlobal';

const SimulationPopup = ({ tableau, enCours, isShown }) => {
  const [heures, setheures] = useState(20);
  const [heuresSemaine, setheuresSemaine] = useState('35');
  const tabHoraires = [
    { value: '35', label: '35 heures' },
    { value: '39', label: '39 heures' },
    { value: '0', label: 'Temps partiel' },
  ];

  return (
    <>
      <h2>Simulation du coût social</h2>
      <div className="simulateur-cout-global">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label htmlFor="nb-heures">
            Indiquez le nombre d&apos;heures travaillées par semaine : &nbsp;
            <Select
              className="select-input"
              value={tabHoraires.find((obj) => obj.value === heuresSemaine)}
              options={tabHoraires}
              onChange={(e) => setheuresSemaine(e.value)}
            />
          </label>
        </div>
        {heuresSemaine === '0' ? (
          <label htmlFor="temps-partiel">
            Si temps partiel alors précisez :&nbsp;
            <input
              type="number"
              min="1"
              value={heures}
              step="0.5"
              max="35"
              id="temps-partiel"
              onChange={(event) => {
                if (
                  event.target.value <= 35 &&
                  (event.target.value.toString().split('.')[1] === '5' ||
                    event.target.value.toString().split('.') <= 35)
                ) {
                  setheures(Number.parseFloat(event.target.value).toFixed(1));
                }
              }}
            />
            &nbsp;heures / semaine
          </label>
        ) : (
          ''
        )}
        <div className="show-position">
          <div className="resultat-glob">
            <BlockHead tableau={tableau} type="actuel" textTop="AVANT" />
            <CoutGlobal
              tableau={tableau}
              first={heuresSemaine}
              second={heures}
              isShown={isShown}
            />{' '}
          </div>
          <div className="resultat-glob">
            <BlockHead
              tableau={enCours}
              type="nouveau"
              textTop="APRÈS"
              palier="true"
            />
            <CoutGlobal
              tableau={enCours}
              first={heuresSemaine}
              second={heures}
              isShown={isShown}
            />{' '}
          </div>
        </div>
        <p className="infos-simulation">
          Nous attirons votre attention sur le fait que les simulations
          réalisées à partir de cet outil n&apos;ont qu&apos;une{' '}
          <span className="bold" style={{ textDecoration: 'underline' }}>
            simple valeur indicative
          </span>
          . Elles sont réalisées à partir de taux moyens de cotisations
          sociales. Elles ne tiennent pas compte des dispositions particulières
          liées à votre convention collective, votre entreprise ou vos salariés
          (prime d&apos;ancienneté, majorations de dimanche, ...). Le salaire
          net exprimé est une estimation avant prélèvement à la source et hors
          complémentaire santé.
        </p>
      </div>
    </>
  );
};

export default SimulationPopup;
