/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Tabs, Tab, Paper, makeStyles } from '@material-ui/core';
import Graphique from './Graphique';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const Onglets = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          centered
        >
          <Tab label="Déjà évalué" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Graphique tab="evalue" />
      </TabPanel>
    </>
  );
};

export default Onglets;
