import propTypes from 'prop-types';
import './TopBar.css';

// eslint-disable-next-line react/prop-types
const TopBar = ({ name, emploi }) => {
  return (
    <header>
      <div className="top-bar">
        <h2 id="nom_personne">{name}</h2>
        <div>
          <p>Emploi :</p>
          <p className="top-bar-emploi bold" id="emploi">
            {emploi}
          </p>
        </div>
      </div>
    </header>
  );
};

export default TopBar;

TopBar.prototype = {
  name: propTypes.string.isRequired,
  emploi: propTypes.string.isRequired,
};
