/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { pdf } from '@react-pdf/renderer';
import { useContext, useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import gender from 'gender-detection';
import { SalarieContext } from './Salaries';
import { EntrepriseContext } from './Documents';
import { TableContext } from '../components/Table';
import CourrierSalarie from './CourrierSalarie';

// eslint-disable-next-line react/prop-types
const DownloadAvenant = () => {
  const [calcul, setcalcul] = useState({});
  const [salarie, setsalarie] = useState({});
  const [dataEntreprise, setdataEntreprise] = useState({});

  const context = useContext(SalarieContext);
  const tableContext = useContext(TableContext);
  const entrepriseContext = useContext(EntrepriseContext);

  const { entreprise } = entrepriseContext;
  const { calculSalarie, infoSalarie } = context;
  const { id } = tableContext;

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  async function getProps() {
    let genre = 'male';
    if (salarie.genre === '' && salarie.nom_prenom.split(' ').length > 1) {
      genre = gender.detect(salarie.nom_prenom.split(' ').slice(-1)[0]);
    }

    if (salarie.genre === 'MME') {
      genre = 'female';
    } else if (salarie.genre === 'M') {
      genre = 'male';
    }
    const split = salarie.nom_prenom.split(' ');
    let nomSal = '';
    if (split.length === 2) {
      nomSal = `${split[1]} ${split[0]}`;
    } else if (split.length === 3) {
      nomSal = `${split[2]} ${split[0]} ${split[1]}`;
    } else {
      nomSal = salarie.nom_prenom;
    }

    await delay(1_000);
    return {
      salarie: salarie,
      calcul: calcul,
      entreprise: dataEntreprise,
      name: nomSal,
      genreSal: genre,
    };
  }

  useEffect(() => {
    setdataEntreprise(entreprise);
    const getAvenant = () => {
      calculSalarie.forEach((element) => {
        if (element.id_salarie === id) {
          setcalcul(element);
        }
      });
      infoSalarie.salaries.forEach((element) => {
        if (element.id === id) {
          setsalarie(element);
        }
      });
    };

    getAvenant();
  }, [calculSalarie, infoSalarie, id, entreprise]);

  return (
    <FontAwesomeIcon
      style={{ cursor: 'pointer' }}
      icon={faDownload}
      onClick={async () => {
        const props = await getProps();
        const doc = <CourrierSalarie {...props} />;
        const asPdf = pdf([]); // {} is important, throws without an argument
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, `${salarie.nom_prenom}_courrier_information.pdf`);
      }}
      color="black"
    />
  );
};

export default DownloadAvenant;
