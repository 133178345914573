import propTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const Erreur = ({ texte }) => {
  return (
    <div
      style={{
        position: 'relative',
        padding: '0.05rem 1.25rem',
        margin: '1rem',
        border: '1px solid transparent',
        borderRadius: '0.25rem',
        color: '#721c24',
        backgroundColor: '#f8d7da',
        borderColor: '#f5c6cb',
      }}
    >
      <p>{texte}</p>
    </div>
  );
};

export default Erreur;

Erreur.prototype = {
  texte: propTypes.string.isRequired,
};
