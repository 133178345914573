/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import { createContext, useEffect, useState, useContext } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import Etape from '../components/Etape';
import Header from '../entreprise/Header';
import Kits from './Kits';
import Salaries from './Salaries';
import Synthese from './Synthese';
import './Documents.css';
import BoutonsGlobaux from './BoutonsGlobaux';
import { JWTContext } from '../App';
import EmailPopup from './EmailPopup';
import Popup from '../components/Popup';

export const EntrepriseContext = createContext();
const Documents = () => {
  const [infoSalarie, setinfoSalarie] = useState([]);
  const [calculSalarie, setcalculSalarie] = useState([]);
  const [lienTerminer, setLienTerminer] = useState('');

  const context = useContext(JWTContext);
  const { JWTString } = context;

  const [emailPopup, setemailPopup] = useState(false);
  const [entreprise, setentreprise] = useState({});

  useEffect(() => {
    const getCalculsSalaries = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/salaries`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then(async (res) => {
          setinfoSalarie(res);
          const tabResult = [];
          const tabCalcul = [];
          res.salaries.forEach((element) => {
            const tabAttente = {
              title: element.nom_prenom,
              id: element.id,
              delete: true,
              activate: false,
              table: [],
            };

            const form = new FormData();
            form.append('id', element.id);

            fetch(`${process.env.REACT_APP_URL}back/api/1.0/calculSal`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                Authorization: `Bearer ${JWTString}`,
              },
              body: form,
            })
              .then((data) => data.json())
              .then((data) => {
                if (data) {
                  tabAttente.activate = true;
                }
                tabCalcul.push(data);
                tabResult.push(tabAttente);
                setcalculSalarie(calculSalarie.concat(tabCalcul));
              });
          });
        });
    };

    getCalculsSalaries();
  }, []);

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  async function getProps() {
    const total = [];
    const newTable = () => {
      infoSalarie.salaries.forEach((data) => {
        calculSalarie.forEach((el) => {
          if (data.id === el.id_salarie) {
            total.push({
              categorie: data.categorie,
              coef: data.coef,
              emploi: data.emploi,
              genre: data.genre,
              nom_prenom: data.nom_prenom,
              qualification: data.qualification,
              salaire_brut: data.salaire_brut,
              nouveau_coef_salarie: el.nouveau_coef_salarie,
              nouveau_palier_salarie: el.nouveau_palier_salarie,
              nouveau_salaire_brut_salarie: el.nouveau_salaire_brut_salarie,
              nouvelle_categorie_salarie: el.nouvelle_categorie_salarie,
            });
          }
        });
      });
    };
    newTable();

    await delay(2_000);
    return {
      salarie: total,
      entreprise: entreprise.nom_entreprise,
      dossier: entreprise.num_dossier_entreprise,
    };
  }

  const sendSynthese = async () => {
    const props = await getProps();
    const doc = <Synthese {...props} />;
    const asPdf = pdf([]); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blob;
  };

  const handleClickEmail = async (param) => {
    if (param) {
      let file = '';
      const blob = sendSynthese();
      await blob
        .then((res) => res)
        .then((res) => {
          file = res;
        });
      const sendFile = async () => {
        const form = new FormData();
        form.append('pdf', file);
        form.append(
          'name',
          `Synthese_avant_apres_${entreprise.num_dossier_entreprise}.pdf`
        );
        await fetch(`${process.env.REACT_APP_URL}back/api/1.0/upload`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${JWTString}`,
          },
          body: form,
        })
          .then((res) => res.text())
          .then((res) => res);
      };

      const sendEmail = async () => {
        const form = new FormData();
        form.append('mail', param);
        form.append('entreprise', entreprise.nom_entreprise);
        form.append(
          'doc',
          `Synthese_avant_apres_${entreprise.num_dossier_entreprise}.pdf`
        );
        await delay(2_000);
        await fetch(`${process.env.REACT_APP_URL}back/api/1.0/mail`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${JWTString}`,
          },
          body: form,
        })
          .then((res) => res.json())
          .then((res) => res)
          .catch((err) => err);
      };
      sendFile();
      sendEmail();
    }

    setemailPopup(!emailPopup);
    return true;
  };

  useEffect(() => {
    const getEntreprise = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/entreprise`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setentreprise(res);
        });
    };
    getEntreprise();

    const getLienTerminer = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/modulable/numero`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => setLienTerminer(res.lien_terminer));
    };
    getLienTerminer();
  }, []);

  return (
    <EntrepriseContext.Provider
      value={{ entreprise, infoSalarie, calculSalarie }}
    >
      <Header
        etape="Etape 3/3"
        titre="Vos documents"
        secondDescription="Téléchargez les documents relatifs à l’entrée en application de la nouvelle Convention Collective Agricole."
      />
      <main className="vos-documents">
        <BoutonsGlobaux handleClickEmail={handleClickEmail} />
        <h2>Documents généraux</h2>
        <Kits />
        <br />
        <h2>Documents personnels au(x) salarié(s)</h2>
        <Salaries />
      </main>
      <Etape
        numEtape="Etape 3/3 : "
        buttonOne="Retour"
        linkOne="emploi"
        buttonTwo="Terminer"
        linkTwoA="oui"
        linkTwo={lienTerminer}
        text="Téléchargez les documents relatifs à l’entrée en application de la nouvelle Convention Collective Agricole."
      />
      <Popup closePopup={handleClickEmail} isShown={emailPopup}>
        <EmailPopup handleClickEmail={handleClickEmail} />
      </Popup>
    </EntrepriseContext.Provider>
  );
};

export default Documents;
