/* eslint-disable import/no-cycle */
/* eslint-disable no-unneeded-ternary */
import ElementTable from './ElementTable';

const ElementTableList = ({ list, download, title }) => {
  return list.map((data) => {
    let avenantpdf = false;
    let courriersal = false;
    let synthese = false;
    if (data.type === 'avenant') {
      avenantpdf = true;
    } else if (data.type === 'lettre_info') {
      courriersal = true;
    } else if (data.type === 'synthese') {
      synthese = true;
    }

    return (
      <ElementTable
        title={title}
        key={data.type}
        leftText={data.leftText}
        leftTextDetail={data.leftTextDetail}
        rightText={data.rightText}
        type={data.type}
        avenantpdf={avenantpdf}
        courriersal={courriersal}
        synthese={synthese}
        download={download}
      />
    );
  });
};

export default ElementTableList;
