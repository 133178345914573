/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useContext, useEffect, useState, useRef } from 'react';
import { SimulateurContext } from './Simulateur';
import { JWTContext } from '../App';
import './PopupEmploi.css';

const PopupEmploi = ({ info, closePopup }) => {
  const context = useContext(SimulateurContext);
  const contextJwt = useContext(JWTContext);
  const { JWTString } = contextJwt;

  const { location, setreloadSalarie } = context;
  const inputRef = useRef();

  const [emploiUpdate, setemploiUpdate] = useState('ouvrier');
  const [emploi, setEmploi] = useState('ouvrier');

  const handleChange = (event) => {
    const { value } = event.target;
    setemploiUpdate(value);
  };

  const modifSalarie = async () => {
    const form = new FormData();
    form.append('emploi', emploiUpdate);
    form.append('id', location.idsal);

    await fetch(`${process.env.REACT_APP_URL}back/api/1.0/salariesModEmploi`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JWTString}`,
      },
      body: form,
    }).then((res) => res.text());
    closePopup();
    setreloadSalarie(true);
  };

  useEffect(() => {
    setEmploi(info);
    setemploiUpdate(info);
    inputRef.current.focus();
  }, [info]);
  return (
    <>
      <h2>L’évaluation de cet emploi a déjà été réalisée</h2>
      <p>
        Vous avez déjà réalisé une évaluation de l&apos;emploi{' '}
        <span style={{ fontFamily: 'Nexa-bold' }}>{emploi}</span> à travers l’un
        de vos salariés.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Pour rappel, la démarche préconisée par la convention collective
        consiste à valoriser l’emploi et non la personne qui l’occupe.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Nous vous invitons à vous reporter à l’évaluation que vous avez déjà
        réalisée de cet emploi.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Si toutefois votre salarié présente des compétences supérieures à celles
        exigées par l’emploi ou à l’inverse ne détient pas toutes les
        compétences requises, alors nous vous invitons à l’affecter sur un
        nouvel emploi en distinguant les intitulés.
        <br />
        <span style={{ fontFamily: 'Nexa-bold' }}>Exemple</span> : « agent de
        production », « ouvrier agricole spécialisé » ou « qualifié » ou «
        hautement qualifié »…
      </p>
      <p>
        Si vous souhaitez modifier l’intitulé de l’emploi pour ce salarié :{' '}
        <input
          id="emploi-input"
          required
          type="text"
          name="emploi"
          ref={inputRef}
          value={emploiUpdate}
          onChange={handleChange}
        />
      </p>
      <div className="bar-button">
        <button
          type="button"
          onClick={() => {
            modifSalarie();
          }}
          className="ok-pour-moi"
        >
          Je continue
        </button>
      </div>
    </>
  );
};

export default PopupEmploi;
