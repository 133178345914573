/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, createContext, useContext } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import gender from 'gender-detection';
import { JWTContext } from '../App';
import Table from '../components/Table';
import { EntrepriseContext } from './Documents';
import CourrierSalarie from './CourrierSalarie';
import Avenant from './Avenant';

export const SalarieContext = createContext();
const Salaries = () => {
  const context = useContext(JWTContext);
  const { JWTString } = context;
  const entrepriseContext = useContext(EntrepriseContext);

  const { entreprise } = entrepriseContext;

  const [resultatSalaries, setresultatSalaries] = useState([]);
  const [infoSalarie, setinfoSalarie] = useState([]);
  const [calculSalarie, setcalculSalarie] = useState([]);
  const [dataEntreprise, setdataEntreprise] = useState({});

  useEffect(() => {
    const getCalculsSalaries = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/salaries`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then(async (res) => {
          setinfoSalarie(res);
          setresultatSalaries([]);
          const tabResult = [];
          const tabCalcul = [];
          res.salaries.forEach((element) => {
            const tabAttente = {
              title: element.nom_prenom,
              id: element.id,
              delete: true,
              activate: false,
              table: [],
            };

            const form = new FormData();
            form.append('id', element.id);

            fetch(`${process.env.REACT_APP_URL}back/api/1.0/calculSal`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                Authorization: `Bearer ${JWTString}`,
              },
              body: form,
            })
              .then((data) => data.json())
              .then((data) => {
                if (data) {
                  tabAttente.activate = true;
                }
                tabCalcul.push(data);
                tabResult.push(tabAttente);
                setcalculSalarie(calculSalarie.concat(tabCalcul));
                setresultatSalaries(resultatSalaries.concat(tabResult));
              });
          });
        });
    };

    getCalculsSalaries();
  }, []);

  const documentsSalaries = [
    {
      leftText:
        'Une lettre d’information personnalisée avec le nouveau positionnement',
      type: 'lettre_info',
    },
    {
      leftText: 'Une fiche de synthèse de l’évaluation réalisée',
      type: 'fiche_synthese',
    },
    {
      leftText:
        'Un avenant au contrat de travail pour acter le nouveau positionnement',
      type: 'avenant',
    },
  ];

  const download = true;

  useEffect(() => {
    setdataEntreprise(entreprise);
  }, [entreprise]);

  const downloadZip = async (title, id) => {
    let calcul = {};
    let salarie = {};

    calculSalarie.forEach((element) => {
      if (element.id_salarie === id) {
        calcul = element;
      }
    });
    infoSalarie.salaries.forEach((element) => {
      if (element.id === id) {
        salarie = element;
      }
    });

    let genre = 'male';
    if (salarie.genre === '' && salarie.nom_prenom.split(' ').length > 1) {
      genre = gender.detect(salarie.nom_prenom.split(' ').slice(-1)[0]);
    }

    if (salarie.genre === 'MME') {
      genre = 'female';
    } else if (salarie.genre === 'M') {
      genre = 'male';
    }
    const split = salarie.nom_prenom.split(' ');
    let nomSal = '';
    if (split.length === 2) {
      nomSal = `${split[1]} ${split[0]}`;
    } else if (split.length === 3) {
      nomSal = `${split[2]} ${split[0]} ${split[1]}`;
    } else {
      nomSal = salarie.nom_prenom;
    }

    const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

    async function getProps() {
      await delay(1_000);
      return {
        salarie: salarie,
        name: nomSal,
        calcul: calcul,
        entreprise: dataEntreprise,
        genreSal: genre,
      };
    }

    async function getPropsCourrier() {
      await delay(2_000);
      return {
        salarie: salarie,
        calcul: calcul,
        name: nomSal,
        entreprise: dataEntreprise,
        genreSal: genre,
      };
    }

    const avenant = async () => {
      const props = await getProps();
      const doc = <Avenant {...props} />;
      const asPdf = pdf([]); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      return blob;
    };

    const courrier = async () => {
      const props = await getPropsCourrier();
      const doc = <CourrierSalarie {...props} />;
      const asPdf = pdf([]); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      return blob;
    };

    const ficheSynthese = async () => {
      let fileData = '';
      const form = new FormData();
      form.append(
        'name',
        `${title.split(' ').join('_')}_nouveau_positionnement.pdf`
      );

      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/uploadedFile`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
        body: form,
      })
        .then((res) => res.blob())
        .then((res) => {
          const file = new Blob([res], {
            type: 'application/pdf;charset=utf-8',
          });
          fileData = file;
        })
        .catch((err) => err);

      return fileData;
    };

    const zip = new JSZip();

    zip.file(`${title.split(' ').join('_')}_avenant_CCNA.pdf`, avenant());
    zip.file(
      `${title.split(' ').join('_')}_courrier_information.pdf`,
      courrier()
    );
    zip.file(
      `${title.split(' ').join('_')}_nouveau_positionnement.pdf`,
      ficheSynthese()
    );

    zip.generateAsync({ type: 'blob' }).then((content) => {
      // see FileSaver.js
      saveAs(content, `Documents_${title.split(' ').join('_')}.zip`);
    });
  };

  return (
    <SalarieContext.Provider value={{ infoSalarie, calculSalarie }}>
      <div className="generaux">
        {resultatSalaries.map((data) => {
          return (
            <Table
              key={data.title}
              tableauElements={documentsSalaries}
              title={data.title}
              download={download}
              activate={data.activate}
              id={data.id}
              downloadZip={downloadZip}
            />
          );
        })}
      </div>
    </SalarieContext.Provider>
  );
};

export default Salaries;
