/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useState, useContext, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import Popup from '../components/Popup';
import PopupAccompagnement from './PopupAccompagnement';
import Buttons from './Buttons';
import Content from './Content';
import CGV from './CGV';
import JeDecouvre from './JeDecouvre';
import './Home.css';
import VideoPopup from './VideoPopup';
import { JWTContext } from '../App';
import Redirection from './Redirection';

const Home = () => {
  const context = useContext(JWTContext);
  const { JWTString } = context;
  const [CGVShown, setCGVShown] = useState(false);
  const [inDatabase, setinDatabase] = useState(false);
  const [JeDecouvreShown, setJeDecouvreShown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [accompagnementShown, setaccompagnementShown] = useState(false);
  const [VideoShown, setVideoShown] = useState(false);
  const [Video, setVideo] = useState('');
  const [demoVideo, setdemoVideo] = useState('');
  const { addToast } = useToasts();

  const handleClickAccompagnement = () => {};

  const handleClickCGV = async (ok, accompagnement) => {
    setCGVShown(!CGVShown);

    if (ok && accompagnement === '2') {
      await fetch(
        `${process.env.REACT_APP_URL}back/api/1.0/mailAccompagnement`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${JWTString}`,
          },
        }
      )
        .then((res) => res.text())
        .then((res) => res);

      addToast('Votre demande d’accompagnement est enregistrée.', {
        appearance: 'success',
      });
      addToast(
        'Un conseiller social va vous recontacter. Vous pouvez fermer la page web.',
        { appearance: 'info' }
      );
    } else if (ok) {
      const form = new FormData();
      form.append('start', true);

      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/entreprise/start`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
        body: form,
      })
        .then((res) => res.text())
        .then((res) => res);

      document.location.reload();
    }
  };

  const handleClickJeDecouvre = () => {
    setJeDecouvreShown(!JeDecouvreShown);
    if (JeDecouvreShown === false) {
      setdemoVideo('Demo-outil.mp4');
    } else {
      setdemoVideo('');
    }
  };

  const handleClickVideo = () => {
    setVideoShown(!VideoShown);
    if (VideoShown === false) {
      setVideo('Convention-agri-national.mp4');
    } else {
      setVideo('');
    }
  };

  useEffect(() => {
    const entrepriseExist = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/entreprise/exist`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setinDatabase(res);
        });
    };

    entrepriseExist();
  }, []);

  return (
    <>
      {inDatabase ? (
        <main className="main-home">
          <section className="content">
            <div className="top">
              <Content handleClickVideo={handleClickVideo} />
              <Buttons
                handleClickCGV={handleClickCGV}
                handleClickJeDecouvre={handleClickJeDecouvre}
              />
            </div>
          </section>
          <div className="img-home"> </div>
          <Popup closePopup={handleClickCGV} isShown={CGVShown}>
            <CGV closePopup={handleClickCGV} />
          </Popup>
          <Popup
            closePopup={handleClickJeDecouvre}
            isShown={JeDecouvreShown}
            addClass="modal-je-decouvre"
          >
            <JeDecouvre
              handleClickCGV={handleClickCGV}
              closePopup={handleClickJeDecouvre}
              demoVideo={demoVideo}
            />
          </Popup>
          <Popup
            closePopup={handleClickVideo}
            isShown={VideoShown}
            addClass="reduce-width"
          >
            <VideoPopup video={Video} />
          </Popup>
          <Popup
            closePopup={handleClickAccompagnement}
            isShown={accompagnementShown}
          >
            <PopupAccompagnement closePopup={handleClickAccompagnement} />
          </Popup>
        </main>
      ) : (
        <Redirection JWTString={JWTString} />
      )}
    </>
  );
};

export default Home;
