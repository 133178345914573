/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import { useContext, useState, useEffect } from 'react';
import Arrow from './Arrow';
import BlockHead from './BlockHead';
import ButtonsCalcul from './ButtonsCalcul';
import { SimulateurContext } from './Simulateur';
import './Head.css';
import Popup from '../components/Popup';
import SimulationPopup from './SimulationPopup';
import { JWTContext } from '../App';

// eslint-disable-next-line react/prop-types
// eslint-disable-next-line no-unused-vars
const Head = ({ oldSalarie, showAlert, idsal }) => {
  const context = useContext(JWTContext);
  const { JWTString } = context;
  const simulateurContext = useContext(SimulateurContext);
  const { CalculOK, location } = simulateurContext;

  const [isshownSimulation, setisshownSimulation] = useState(false);

  const [warningText, setwarningText] = useState('');
  const [newSalarie, setnewSalarie] = useState({
    categorie: '',
    qualification: '',
    coefficient: '',
    salaire: '',
  });

  const [enCours, setenCours] = useState({
    categorie: '',
    qualification: '',
    coefficient: '',
    salaire: '',
  });

  useEffect(() => {
    const getCalcul = async () => {
      const form = new FormData();
      form.append('id', location.idsal);
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/calculSal`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
        body: form,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.json_calcul) {
            const salarie = {
              id: idsal,
              categorie: res.nouvelle_categorie_salarie.toString(),
              qualification: res.nouveau_palier_salarie.toString(),
              coefficient: res.nouveau_coef_salarie.toString(),
              salaire: res.nouveau_salaire_brut_salarie.toString(),
            };
            setenCours(salarie);
            setnewSalarie(salarie);
          }
        });
    };

    getCalcul();
  }, []);

  // [ Palier, coef_min, coef_max, taux_horaire]
  const tauxHoraire = [
    [1, 9, 11, 10.25],
    [2, 12, 16, 10.33],
    [3, 17, 24, 10.48],
    [4, 25, 35, 10.71],
    [5, 36, 51, 11.21],
    [6, 52, 73, 11.77],
    [7, 74, 104, 12.47],
    [8, 105, 143, 13.37],
    [9, 144, 196, 14.49],
    [10, 197, 270, 16.06],
    [11, 271, 399, 18.28],
    [12, 400, 400, 20.91],
  ];

  const valueInput = [
    [null, 2, 4, 16, 38, 100],
    [null, 2, 7, 28, 100],
    [null, 1, 4, 25],
    [null, 1, 4, 25],
    [null, 2, 3, 10, 23, 48, 100],
    [null, 1, 4, 14, 50],
  ];

  function strUcFirst(a) {
    return `${a} `.charAt(0).toUpperCase() + a.substr(1);
  }

  const getResultCalcul = () => {
    if (CalculOK.every((data) => data !== 0)) {
      let somme = 0;
      let i = 0;
      let Coef = 0;
      let Salaire = 0;
      let Categorie = '';
      let Palier = '';
      if (valueInput.length === 6) {
        CalculOK.forEach((element) => {
          somme += valueInput[i][element];
          i += 1;
        });
        Coef = somme;
        tauxHoraire.forEach((element) => {
          if (Coef >= element[1] && Coef <= element[2]) {
            if (oldSalarie.salaire > element[3]) {
              Salaire = oldSalarie.salaire;
              setwarningText(
                'Le salaire conventionnel est inférieur au salaire actuel = maintien de salaire'
              );
            } else {
              Salaire = element[3];
              setwarningText('');
            }

            Palier = element[0];

            if (
              Coef >= 197 &&
              CalculOK[1] >= 4 &&
              (CalculOK[4] >= 4 || CalculOK[0] >= 4)
            ) {
              Categorie = 'Cadre';
            } else if (
              Coef >= 105 &&
              CalculOK[1] >= 3 &&
              (CalculOK[4] >= 3 || CalculOK[0] >= 4)
            ) {
              Categorie = 'Agent de maîtrise';
            } else if (
              Coef >= 74 &&
              CalculOK[0] >= 4 &&
              ((CalculOK[2] >= 3 && CalculOK[3] >= 3) || CalculOK[1] >= 3)
            ) {
              Categorie = 'Technicien';
            } else {
              if (
                oldSalarie.categorie.trim() === 'employe' ||
                oldSalarie.categorie.trim() === 'Employé' ||
                oldSalarie.categorie.trim() === 'Employée' ||
                oldSalarie.categorie.trim() === 'employée' ||
                oldSalarie.categorie.trim() === 'employee' ||
                oldSalarie.categorie.trim() === 'Employ' ||
                oldSalarie.categorie.trim() === 'mploy' ||
                oldSalarie.categorie.trim() === 'EMPLOYE' ||
                oldSalarie.categorie.trim() === 'EMPLOYEE' ||
                oldSalarie.categorie.trim() === 'ploye' ||
                oldSalarie.categorie.trim() === 'emploier' ||
                oldSalarie.categorie.trim() === 'emploie' ||
                oldSalarie.categorie.trim() === 'emploier' ||
                oldSalarie.categorie.trim() === 'emploiee' ||
                oldSalarie.categorie.trim() === 'amployer' ||
                oldSalarie.categorie.trim() === 'Employ?' ||
                oldSalarie.categorie.trim() === 'Employ??' ||
                oldSalarie.categorie.trim() === 'Employéé' ||
                oldSalarie.categorie.trim() === 'amployé'
              ) {
                Categorie = 'Employé';
              } else {
                Categorie = 'Ouvrier';
              }
            }
          }
        });
      }
      if (
        strUcFirst(oldSalarie.categorie.toLowerCase()) !== Categorie &&
        Categorie !== 'Employé'
      ) {
        showAlert();
      }
      const salarie = {
        id: idsal,
        categorie: Categorie.toString(),
        qualification: Palier.toString(),
        coefficient: Coef.toString(),
        salaire: Salaire.toString(),
      };
      setenCours(salarie);
      setnewSalarie(salarie);
    }
  };

  const showPopupSimulation = () => {
    setisshownSimulation(!isshownSimulation);
  };
  return (
    <section className="head-assistant">
      <div className="position-actuel">
        <BlockHead
          tableau={oldSalarie}
          type="actuel"
          textTop="Positionnement actuel"
        />
      </div>
      <Arrow />
      <div className="position-nouveau">
        <BlockHead
          tableau={newSalarie}
          type="nouveau"
          textTop="Nouveau positionnement"
          absoluteText={warningText}
          infoIcon="all"
          palier="true"
        />
      </div>
      <ButtonsCalcul
        tableau={oldSalarie}
        newCalcul={newSalarie}
        startCalcul={getResultCalcul}
        showPopup={showPopupSimulation}
      />
      <Popup closePopup={showPopupSimulation} isShown={isshownSimulation}>
        <SimulationPopup
          tableau={oldSalarie}
          enCours={enCours}
          isShown={isshownSimulation}
        />
      </Popup>
    </section>
  );
};

export default Head;
