/* eslint-disable camelcase */
/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import {
  TableCell,
  TableBody,
  Table,
  TableHeader,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';

Font.register({
  family: 'Calibri',
  fonts: [
    { src: '/Calibri_Light.ttf' },
    { src: '/Calibri_Bold.ttf', fontWeight: 700 },
    { src: '/Calibri_Regular.ttf', fontWeight: 400 },
  ],
});

const Synthese = ({ salarie, entreprise, dossier }) => {
  if (salarie) {
    return (
      <Document>
        <Page
          style={{
            fontSize: 11,
            fontFamily: 'Calibri',
            paddingTop: 60,
            paddingBottom: 65,
            paddingHorizontal: 70,
            color: 'black',
          }}
        >
          <Text style={{ fontWeight: 700, fontSize: '13' }}>
            {entreprise} - N°{dossier}
          </Text>
          <Text> </Text>
          <Text
            style={{ textAlign: 'center', fontSize: '15', marginBottom: '15' }}
          >
            Synthèse de tous les positionnements de vos salariés « avant / après
            »{' '}
          </Text>
          {salarie.map((sal) => {
            const { genre, nom_prenom, emploi } = sal;
            return (
              <>
                <Text> </Text>
                <Text> </Text>
                <Text style={{ fontWeight: 700 }}>
                  {genre} {nom_prenom}
                </Text>
                <Text>Emploi : {emploi}</Text>
                <Table data={[sal]}>
                  <TableHeader>
                    <TableCell style={{ textAlign: 'center' }}>Avant</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>Après</TableCell>
                  </TableHeader>
                  <TableBody>
                    <DataTableCell
                      style={{ padding: '5' }}
                      getContent={(r) => {
                        const {
                          categorie,
                          qualification,
                          coef,
                          salaire_brut,
                        } = r;
                        return (
                          <>
                            <Text>Catégorie : {categorie}</Text>
                            <Text>Qualification : {qualification}</Text>
                            <Text>Coefficient : {coef}</Text>
                            <Text>Salaire horaire brut : {salaire_brut} €</Text>
                          </>
                        );
                      }}
                    />
                    <DataTableCell
                      style={{ padding: '5' }}
                      getContent={(r) => {
                        const {
                          nouvelle_categorie_salarie,
                          nouveau_palier_salarie,
                          nouveau_coef_salarie,
                          nouveau_salaire_brut_salarie,
                        } = r;
                        return (
                          <>
                            <Text>
                              Catégorie : {nouvelle_categorie_salarie}
                            </Text>
                            <Text>Palier : {nouveau_palier_salarie}</Text>
                            <Text>Coefficient :{nouveau_coef_salarie}</Text>
                            <Text>
                              Salaire horaire brut :{' '}
                              {nouveau_salaire_brut_salarie} €
                            </Text>
                          </>
                        );
                      }}
                    />
                  </TableBody>
                </Table>
              </>
            );
          })}
        </Page>
      </Document>
    );
  } else {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>This Pdf could not be generated</Text>
          </View>
        </Page>
      </Document>
    );
  }
};

export default Synthese;
