const Arrow = () => {
  return (
    <div className="div-calcul">
      <svg
        height="100"
        viewBox="0 -77 512 512"
        width="100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m292.246094 357.222656v-81.195312h-163.1875v-194.832032h163.1875v-81.195312l219.753906 178.613281zm0 0"
          fill="#f8b332"
        />
        <g fill="#d0962a">
          <path d="m16.234375 276.027344c-8.960937 0-16.234375-7.273438-16.234375-16.234375v-162.359375c0-8.964844 7.273438-16.238282 16.234375-16.238282 8.964844 0 16.238281 7.273438 16.238281 16.238282v162.359375c0 8.960937-7.273437 16.234375-16.238281 16.234375zm0 0" />
          <path d="m81.179688 276.027344c-8.960938 0-16.234376-7.273438-16.234376-16.234375v-162.359375c0-8.964844 7.273438-16.238282 16.234376-16.238282 8.960937 0 16.234374 7.273438 16.234374 16.238282v162.359375c0 8.960937-7.273437 16.234375-16.234374 16.234375zm0 0" />
          <path d="m292.246094 357.222656v-81.195312h-163.1875v-97.414063h382.941406zm0 0" />
        </g>
      </svg>
    </div>
  );
};

export default Arrow;
