/* eslint-disable react/prop-types */
import propTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './Button.css';

const Button = ({
  buttonClass,
  type,
  value,
  handleClick,
  closePopup,
  accompagnement,
  link,
}) => {
  const { push } = useHistory();
  return (
    <>
      <button
        onClick={() => {
          if (value === 'Je continue') {
            handleClick(true, accompagnement);
          } else {
            handleClick();
          }
          closePopup();
          if (link) {
            push(`/${link}`);
          }
        }}
        type="button"
        className={buttonClass}
        style={{
          color: type === 'green' ? 'white' : '#32ba7c',
          fontFamily: type === 'green' ? 'Nexa-Light' : 'Nexa-bold',
        }}
      >
        {value}
      </button>
    </>
  );
};

export default Button;

Button.prototype = {
  type: propTypes.string.isRequired,
  href: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
};

Button.defaultProps = {
  closePopup: () => {
    return false;
  },
  handleClick: () => {
    return false;
  },
};
