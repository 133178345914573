/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './AjoutSalarie.css';

const AjoutSalarie = ({ handleClickAjout }) => {
  return (
    <div className="ajout-salarie" onClick={() => handleClickAjout()}>
      <div className="filtre">
        <img src="img/plus.svg" alt="icon plus" width="75" height="75" />
        <h2>Ajouter un salarié</h2>
      </div>
    </div>
  );
};

export default AjoutSalarie;
