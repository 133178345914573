/* eslint-disable camelcase */
/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import { TableCell, TableHeader } from '@david.kucsai/react-pdf-table';

Font.register({
  family: 'Calibri',
  fonts: [
    { src: '/Calibri_Light.ttf' },
    { src: '/Calibri_Bold.ttf', fontWeight: 700 },
    { src: '/Calibri_Regular.ttf', fontWeight: 400 },
  ],
});

const Avenant = ({ salarie, calcul, entreprise, name, genreSal }) => {
  const { adresse, nom_prenom, num_securite_sociale, emploi } = salarie;
  const {
    nouveau_coef_salarie,
    nouveau_palier_salarie,
    nouveau_salaire_brut_salarie,
    nouvelle_categorie_salarie,
  } = calcul;
  const {
    ape_entreprise,
    dirigeants_entreprise,
    nom_entreprise,
    siege_social_entreprise,
    siret_entreprise,
  } = entreprise;
  const dirigeant = dirigeants_entreprise;
  if (adresse) {
    return (
      <Document>
        <Page
          style={{
            fontSize: 11,
            fontFamily: 'Calibri',
            paddingTop: 60,
            paddingBottom: 65,
            paddingHorizontal: 70,
            color: 'black',
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              fontSize: 25,
              paddingBottom: 5,
            }}
          >
            Avenant au contrat de travail
          </Text>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 15,
              fontWeight: 500,
              paddingBottom: 25,
            }}
          >
            Application de la nouvelle convention collective agricole
          </Text>
          <Text style={{ fontWeight: 700, paddingBottom: 10 }}>Entre :</Text>
          <Text style={{ fontWeight: 700, paddingLeft: 30 }}>
            La Société {nom_entreprise}
          </Text>
          <Text style={{ paddingLeft: 30 }}>
            Ayant son siège social à {siege_social_entreprise}
          </Text>
          <Text style={{ paddingLeft: 30 }}>SIRET : {siret_entreprise}</Text>
          <Text style={{ paddingLeft: 30 }}>APE / NAF : {ape_entreprise}</Text>
          <Text style={{ paddingLeft: 30 }}>
            Et representée par {dirigeant}
          </Text>
          <Text style={{ fontWeight: 700, paddingLeft: 30 }}>
            d&apos;une part,
          </Text>
          <Text style={{ fontWeight: 700, paddingBottom: 10, paddingTop: 10 }}>
            Et
          </Text>
          <Text style={{ fontWeight: 700, paddingLeft: 30 }}>
            {genreSal === 'female' ? 'Madame' : 'Monsieur'} {name}
          </Text>
          <Text style={{ paddingLeft: 30 }}>demeurant à : {adresse}</Text>
          <Text style={{ paddingLeft: 30 }}>NIR : {num_securite_sociale}</Text>
          <Text style={{ fontWeight: 700, paddingLeft: 30 }}>
            d&apos;autre part
          </Text>
          <Text style={{ paddingTop: 25, paddingBottom: 10 }}>
            Préambule :{' '}
          </Text>
          <Text style={{ textAlign: 'justify' }}>
            A compter du 1er avril 2021, une nouvelle convention collective
            nationale entre en application de manière obligatoire auprès de
            l’ensemble des entreprises et exploitations de la production
            agricole et des CUMA.
          </Text>
          <Text style={{ textAlign: 'justify', paddingTop: 10 }}>
            Cette nouvelle convention collective du 15 septembre 2020, IDCC
            7024, vise à définir un cadre conventionnel commun à toutes les
            régions et à tous les secteurs d’activités de la production
            agricole, dans la gestion des relations au sein des entreprises
            entre les employeurs et les salariés.
          </Text>
          <Text style={{ textAlign: 'justify', paddingTop: 10 }}>
            Cette convention collective nationale se positionne entre la loi et
            les accords locaux, de tel sorte que les conventions collectives
            territoriales et professionnelles existantes ne sont pas remises en
            causes. Elles continueront donc à s’appliquer et à produire leurs
            effets pour certaines de leurs dispositions particulières.
          </Text>
          <Text style={{ textAlign: 'justify', paddingTop: 10 }}>
            La convention collective du 15 septembre 2020 prévoit notamment une
            nouvelle grille de classification des emplois et des compétences,
            unique et commune à toutes les entreprises.
          </Text>
          <Text style={{ textAlign: 'justify', paddingTop: 10 }}>
            Dans le cadre de l’entrée en application de cette convention
            collective, il appartenait aux employeurs de procéder à une
            évaluation et un repositionnement de l’ensemble des emplois présents
            au sein de leur entreprise, selon les critères définis par cette
            nouvelle grille de classification.
          </Text>
          <Text style={{ textAlign: 'justify', paddingTop: 10 }}>
            Dans un souci de clarté et de transparence par rapport à cette
            démarche, les parties conviennent par cet avenant de contractualiser
            le repositionnement de{' '}
            {genreSal === 'female' ? 'Madame' : 'Monsieur'} {nom_prenom}
          </Text>
          <Text style={{ paddingTop: 35, paddingBottom: 35, fontWeight: 700 }}>
            Il a été convenu ce qui suit :
          </Text>
          <Text style={{ textAlign: 'justify' }}>
            Selon la nouvelle classification prévue par la convention collective
            nationale production agricole / CUMA du 15 septembre 2020, et la
            valorisation de l’emploi réalisée préalablement par l’employeur,{' '}
            {genreSal === 'female' ? 'Madame' : 'Monsieur'} {nom_prenom}{' '}
            exercera à compter du 1er avril 2021 ses fonctions au sein de
            l’entreprise en qualité de :
          </Text>
          <Text style={{ paddingLeft: 30, paddingTop: 15 }}>
            Emploi : {emploi}
          </Text>
          <Text style={{ paddingLeft: 30 }}>
            Palier : {nouveau_palier_salarie}
          </Text>
          <Text style={{ paddingLeft: 30 }}>
            Coefficient : {nouveau_coef_salarie}
          </Text>
          <Text style={{ paddingLeft: 30 }}>
            Catégorie socio-professionnelle de l’emploi :{' '}
            {nouvelle_categorie_salarie}
          </Text>
          <Text style={{ paddingTop: 15, textAlign: 'justify' }}>
            {genreSal === 'female' ? 'Madame' : 'Monsieur'} {name} est{' '}
            {genreSal === 'female' ? 'engagée' : 'engagé'}&nbsp; selon les
            conditions prévues par la convention collective nationale du 15
            septembre 2020 et les accords locaux éventuellement existants.
          </Text>
          <Text style={{ paddingTop: 15, textAlign: 'justify' }}>
            {genreSal === 'female' ? 'Madame' : 'Monsieur'} {name} exercera ses
            fonctions selon les attendus et les critères décrits au sein de la
            classification et correspondant à son emploi.{' '}
            {genreSal === 'female' ? 'Elle' : 'Il'}&nbsp;exercera également ses
            fonctions selon les directives et les orientations qui lui seront
            données par l’employeur.
          </Text>
          <Text
            style={{ paddingTop: 15, paddingBottom: 40, textAlign: 'justify' }}
          >
            En contrepartie de son travail,{' '}
            {genreSal === 'female' ? 'Madame' : 'Monsieur'} {name} bénéficiera
            d’une rémunération établie sur la base d’un taux horaire brut de{' '}
            {nouveau_salaire_brut_salarie} €.
          </Text>
          <Text>Fait à {siege_social_entreprise}</Text>
          <Text>Le </Text>
          <Text style={{ paddingBottom: 20 }}>En deux exemplaires</Text>
          <TableHeader style={{ borderWidth: 0 }}>
            <TableCell style={{ borderWidth: 0, padding: 10 }}>
              <Text style={{ fontWeight: 700, fontSize: '12' }}>
                L’EMPLOYEUR
              </Text>
              <Text style={{ fontSize: '12' }}>{nom_entreprise}</Text>
              <Text style={{ paddingBottom: 120, fontSize: '12' }}>
                {dirigeant}
              </Text>
            </TableCell>
            <TableCell style={{ borderWidth: 0, padding: 10 }}>
              <Text style={{ fontWeight: 700, fontSize: '12' }}>
                LE SALARIE
              </Text>
              <Text style={{ fontSize: '12' }}>
                {genreSal === 'female' ? 'Madame' : 'Monsieur'} {name} *
              </Text>
              <Text style={{ paddingBottom: 120 }}> </Text>
            </TableCell>
          </TableHeader>
          <Text style={{ fontSize: 10, paddingTop: 10 }}>
            * signatures précédées de la mention manuscrite « Lu et approuvé »
            et paraphes en bas de chaque pages.
          </Text>
        </Page>
      </Document>
    );
  } else {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>This Pdf could not be generated</Text>
          </View>
        </Page>
      </Document>
    );
  }
};

export default Avenant;
