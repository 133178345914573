/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import propTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Etape.css';
import Popup from './Popup';
import PopupTerminer from './PopupTerminer';

// eslint-disable-next-line react/prop-types
const Etape = ({
  numEtape,
  linkOne,
  buttonOne,
  text,
  buttonTwo,
  linkTwo,
  linkTwoA,
}) => {
  const { push } = useHistory();
  const [handleClickPopupTerminer, sethandleClickPopupTerminer] = useState(
    false
  );
  const clickPopupTerminer = () => {
    sethandleClickPopupTerminer(!handleClickPopupTerminer);
  };

  return (
    <>
      <div className="etape">
        {buttonOne ? (
          <button
            onClick={() => {
              if (linkOne) {
                push(`/${linkOne}`);
              }
            }}
            type="button"
            className="etape-retour"
          >
            {buttonOne}
          </button>
        ) : (
          ''
        )}
        <p>
          {numEtape}
          <span className="description-global">{text}</span>
        </p>
        {buttonTwo && linkTwoA ? (
          <button
            onClick={() => {
              if (linkTwo) {
                clickPopupTerminer();
              }
            }}
            type="button"
            className="etape-validate"
          >
            {buttonTwo}
          </button>
        ) : buttonTwo ? (
          <button
            onClick={() => {
              if (linkTwo) {
                push(`/${linkTwo}`);
              }
            }}
            type="button"
            className="etape-validate"
          >
            {buttonTwo}
          </button>
        ) : (
          <div> </div>
        )}
        <Popup
          closePopup={clickPopupTerminer}
          isShown={handleClickPopupTerminer}
        >
          <PopupTerminer linkTwo={linkTwo} />
        </Popup>
      </div>
    </>
  );
};

export default Etape;

Etape.prototype = {
  numEtape: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
};
