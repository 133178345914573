// eslint-disable-next-line react/prop-types
const VideoPopup = ({ video }) => {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video style={{ maxHeight: '60vh' }} src={video} controls poster="">
      Cette vidéo ne peut être affichée sur votre navigateur Internet.
      <br />
    </video>
  );
};

export default VideoPopup;
