/* eslint-disable react/prop-types */
const PopupAccompagnement = ({ closePopup }) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <h2
        style={{
          color: 'black',
          margin: '2em',
          marginBottom: '1em',
          marginTop: '1em',
        }}
      >
        Votre demande d’accompagnement est enregistrée.
      </h2>
      <p style={{ color: 'black', marginBottom: '2em' }}>
        Un conseiller social va vous recontacter. Vous pouvez fermer la page
        web.
      </p>
      <button
        style={{ color: 'white', fontFamily: 'Nexa-Light', fontSize: '1.2em' }}
        onClick={() => {
          closePopup();
        }}
        type="button"
        className="start"
      >
        Terminer
      </button>
    </div>
  );
};

export default PopupAccompagnement;
