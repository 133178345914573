/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-fragments */
import { createContext, useState } from 'react';
import { AppBar, Toolbar, Button, Typography } from '@material-ui/core';
import './BackOffice.css';
import Choice from './Choice';
import Onglets from './Onglets';

export const DataContext = createContext();
const BackOffice = ({ jwt, right, login, listCode }) => {
  const [Code, setCode] = useState(listCode.length > 0 ? listCode[0] : '');
  const aujourdhui = new Date();
  const [selectedDate, setSelectedDate] = useState({
    debut: '2021-03-10',
    fin: aujourdhui.toISOString().slice(0, 10),
  });

  return (
    <DataContext.Provider
      value={{
        selectedDate,
        setSelectedDate,
        jwt,
        Code,
        setCode,
        right,
        listCode,
      }}
    >
      <AppBar position="static">
        <Toolbar className="top-bar-backoffice">
          <Typography variant="h6">Bonjour, {login}</Typography>
          <Button
            className="disconnect-top-bar"
            onClick={() => window.location.reload()}
            color="inherit"
          >
            Se deconnecter
          </Button>
        </Toolbar>
      </AppBar>
      <div className="window-backoffice">
        <Choice />
        <div className="graphs">
          <Onglets />
        </div>
      </div>
    </DataContext.Provider>
  );
};

export default BackOffice;
