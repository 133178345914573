/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useContext, useEffect, useState } from 'react';
import { JWTContext } from '../App';

const PopupHelp = () => {
  const context = useContext(JWTContext);
  const { JWTString } = context;
  const [numeroHotline, setnumeroHotline] = useState('');

  useEffect(() => {
    const getNumero = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/modulable/numero`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => setnumeroHotline(res.numero_tel_hotline));
    };
    getNumero();
  }, []);

  const downloadNotice = () => {
    fetch('Notice_utilisation_outil_ccna.pdf', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${JWTString}`,
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'Notice_utilisation_outil_ccna.pdf';
        element.click();
      });
  };

  return (
    <>
      <h2 style={{ fontSize: '2.5em', color: 'black' }}>
        Assistance module CCNA
      </h2>
      <section
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <button
          onClick={() => {
            downloadNotice();
          }}
          style={{
            boxShadow:
              'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
            display: 'flex',
            alignItems: 'center',
            height: '80%',
            justifyContent: 'center',
            fontSize: '1.3em',
            width: '25em',
            padding: '0.75rem 2rem',
            border: 'solid 1px transparent',
            borderRadius: '0.25rem',
            color: 'white',
            backgroundColor: '#18a096',
            textDecoration: 'none',
            fontFamily: 'Nexa-light',
            marginLeft: '1em',
          }}
          type="button"
        >
          Télécharger la notice d&apos;utilisation du module
        </button>

        {numeroHotline ? (
          <>
            <p style={{ fontSize: '1.1em', paddingTop: '2em' }}>
              Si vous utilisez le navigateur Google Chrome et qu&apos;une page
              blanche s&apos;affiche lors du repositionnement d&apos;un emploi,
              nous vous invitons à réessayer en utilisant un navigateur Edge ou
              Firefox. Notre équipe est en train de résoudre le problème.
            </p>
            <p style={{ fontSize: '1.1em' }}>
              En cas de difficultés dans l’utilisation de l’outil, nous vous
              invitons à contacter notre assistance au{'  '}
              <span style={{ fontFamily: 'Nexa-bold' }}>{numeroHotline}</span>
            </p>
          </>
        ) : (
          ''
        )}
      </section>
    </>
  );
};

export default PopupHelp;
