import propTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const DivPositionnement = ({ bold, classtoAdd, text }) => {
  return (
    <div className={`column-position ${classtoAdd}`}>
      <h2 className={bold ? 'bold' : ''}>{text}</h2>
    </div>
  );
};

export default DivPositionnement;

DivPositionnement.prototype = {
  text: propTypes.string.isRequired,
};

DivPositionnement.default = {
  classtoAdd: '',
};
