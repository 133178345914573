/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useEffect, useState, useContext } from 'react';
import { JWTContext } from '../App';
import DivPositionnement from '../components/DivPositionnement';
import Etape from '../components/Etape';
import Header from '../entreprise/Header';
import './Emploi.css';
import LineEmploiList from './LineEmploiList';

const Emploi = () => {
  const [fetchSalaries, setfetchSalaries] = useState([]);
  const context = useContext(JWTContext);
  const { JWTString } = context;

  useEffect(() => {
    const getEntreprise = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/salaries`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const fetch = [];
          if (res.salaries.length >= 1) {
            res.salaries.forEach((element) => {
              fetch.push({
                title: element.nom_prenom,
                delete: true,
                id_sal: element.id,
                emploi: element.emploi,
                edit: true,
                table: [
                  {
                    leftText: 'Adresse',
                    rightText: element.adresse,
                    type: 'adresse',
                  },
                  {
                    leftText: 'Nationalité',
                    rightText: element.nationalite,
                    type: 'nationalite',
                  },
                  {
                    leftText: 'N°Sécurité Sociale',
                    rightText: element.num_securite_sociale,
                    type: 'num_ss',
                  },
                  {
                    leftText: 'Emploi',
                    rightText: element.emploi,
                    type: 'emploi-salarie',
                  },
                  {
                    leftText: 'Catégorie',
                    rightText: element.categorie,
                    type: 'categorie',
                  },
                  {
                    leftText: 'Qualification',
                    rightText: element.qualification,
                    type: 'qualif',
                  },
                  {
                    leftText: 'Salaire horaire Brut',
                    rightText: element.salaire_brut,
                    type: 'salaire',
                  },
                  {
                    leftText: 'Coefficient',
                    rightText: element.coef,
                    type: 'coef',
                  },
                ],
              });
            });
            setfetchSalaries(fetch);
          }
        })
        .catch((err) => err);
    };

    getEntreprise();
  }, []);

  return (
    <>
      <Header
        etape="Etape 2/3"
        titre="Evaluer les emplois"
        secondDescription="Identifiez pour chacun des emplois les compétences requises au regard des
critères définis par la convention collective."
      />
      <main>
        <section className="head-position">
          <div className="person"> </div>
          <DivPositionnement
            bold
            classtoAdd="actuel"
            text="Positionnement actuel"
          />
          <DivPositionnement
            classtoAdd="nouveau"
            text="Nouveau positionnement"
          />
          <div className="validation"> </div>
        </section>
        <section className="body-position">
          <LineEmploiList salarie={fetchSalaries} />
        </section>
      </main>
      <Etape
        numEtape="Etape 2/3 : "
        buttonOne="Retour"
        linkOne="votre-entreprise"
        buttonTwo="Accéder aux documents"
        linkTwo="vos-documents"
        text="Identifiez pour chacun des emplois les compétences requises au regard des critères définis par la convention collective."
      />
    </>
  );
};

export default Emploi;
