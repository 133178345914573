/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import propTypes from 'prop-types';
import { useState } from 'react';
import Table from '../components/Table';
import './Header.css';
import Popup from '../components/Popup';
import PopupHelp from '../components/PopupHelp';

const Header = ({
  etape,
  titre,
  description,
  secondDescription,
  tableau,
  handleClickEdit,
  nomEntreprise,
}) => {
  const download = false;
  const [isShownHelpPopup, setisShownHelpPopup] = useState(false);

  const handleClickHelpPopup = () => {
    setisShownHelpPopup(!isShownHelpPopup);
  };

  return (
    <>
      <header className="dark">
        <div>
          <h2>{etape}</h2>
          <h1 className="title-global">{titre}</h1>
          {description ? (
            <div style={{ display: 'flex', marginRight: '2em' }}>
              <span
                style={{ paddingRight: '1em' }}
                className="description-header"
              >
                {description}{' '}
              </span>
              <button
                className="button-aide"
                type="button"
                onClick={() => handleClickHelpPopup()}
              >
                Aide
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
        {tableau ? (
          <Table
            editTab="true"
            title={nomEntreprise}
            tableauElements={tableau}
            handleClickEdit={handleClickEdit}
            download={download}
          />
        ) : (
          ''
        )}

        {secondDescription ? (
          <section style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{ paddingRight: '1em' }}
              className="description-header"
            >
              {secondDescription}{' '}
            </span>
            <button
              className="button-aide"
              type="button"
              onClick={() => handleClickHelpPopup()}
            >
              Aide
            </button>
          </section>
        ) : (
          ''
        )}
      </header>
      <Popup closePopup={handleClickHelpPopup} isShown={isShownHelpPopup}>
        <PopupHelp />
      </Popup>
    </>
  );
};

export default Header;

Header.prototype = {
  etape: propTypes.string.isRequired,
  titre: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
};
