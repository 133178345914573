const InfoPopup = () => {
  const downloadFile = async () => {
    await fetch('Grille_des_positionnement_&_des_salaires.pdf', {
      method: 'GET',
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'Grille_des_positionnement_&_des_salaires.pdf';
        element.click();
      });
  };

  return (
    <>
      <h2>Grille des salaires (étendue)</h2>
      <p>
        Vous trouverez ci-dessous la grille des salaires applicable à ce jour.
        <br />
        Nous attirons votre attention sur le fait qu’une nouvelle grille des
        salaires est actuellement en cours d’extension. Celle-ci prévoit une
        revalorisation moyenne des taux horaire de rémunération d’environ 0,12
        €. <br />
        <br />
      </p>
      <img
        className="img-modal"
        src="img/tableau_classification_emploi.png"
        alt="tableau_classification_emploi"
      />
      <button
        onClick={() => downloadFile()}
        className="download-position"
        type="button"
      >
        Télécharger le document
      </button>
    </>
  );
};

export default InfoPopup;
