/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable new-cap */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
import propTypes from 'prop-types';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import DownloadAvenant from '../documents/DownloadAvenant';
import DownloadCourrier from '../documents/DownloadCourrier';
import { JWTContext } from '../App';
import DownloadSynthese from '../documents/DownloadSynthese';

const ElementTable = ({
  leftText,
  leftTextDetail,
  rightText,
  type,
  download,
  avenantpdf,
  synthese,
  courriersal,
  title,
}) => {
  const context = useContext(JWTContext);
  const { JWTString } = context;

  const note = () => {
    fetch('Note_information_1.pdf', {
      method: 'GET',
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'Note_information_application_CCN.pdf';
        element.click();
      });
  };

  const noteDispo = () => {
    fetch('Note_information_2.pdf', {
      method: 'GET',
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'Note_information_CCN_applicable.pdf';
        element.click();
      });
  };

  const positionnement = () => {
    fetch('Grille_des_positionnement_&_des_salaires.pdf', {
      method: 'GET',
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'Grille_des_positionnement_&_des_salaires.pdf';
        element.click();
      });
  };

  const classification = () => {
    fetch('Grille_de_classification_des_emplois.pdf', {
      method: 'GET',
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'Grille_de_classification_des_emplois.pdf';
        element.click();
      });
  };

  const convention = () => {
    fetch('Convention_collective_nationale_production_agricole.pdf', {
      method: 'GET',
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download =
          'Convention_collective_nationale_production_agricole.pdf';
        element.click();
      });
  };

  const ficheSynthese = () => {
    const form = new FormData();
    form.append(
      'name',
      `${title.split(' ').join('_')}_nouveau_positionnement.pdf`
    );

    fetch(`${process.env.REACT_APP_URL}back/api/1.0/uploadedFile`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JWTString}`,
      },
      body: form,
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = `${title
          .split(' ')
          .join('_')}_nouveau_positionnement.pdf`;
        element.click();
      })
      .catch((err) => err);
  };

  const avenant = async () => {};

  const downloadFile = (typeDoc) => {
    switch (typeDoc) {
      case 'positionnement':
        positionnement();
        break;
      case 'classification':
        classification();
        break;
      case 'ccn':
        convention();
        break;
      case 'note':
        note();
        break;
      case 'note-dispo':
        noteDispo();
        break;
      case 'avenant':
        avenant();
        break;
      case 'fiche_synthese':
        ficheSynthese();
        break;
      default:
        break;
    }
  };

  return (
    <div className="element">
      <dt>
        {leftText}
        {leftTextDetail ? (
          <span className="details">{leftTextDetail}</span>
        ) : (
          ''
        )}
      </dt>
      <dd className={type}>
        {download && avenantpdf ? (
          <DownloadAvenant downloadFile={downloadFile} type={type} />
        ) : download && courriersal ? (
          <DownloadCourrier />
        ) : download && synthese ? (
          <DownloadSynthese />
        ) : download ? (
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faDownload}
            onClick={() => downloadFile(type)}
            color="black"
          />
        ) : (
          rightText
        )}
      </dd>
    </div>
  );
};

export default ElementTable;

ElementTable.prototype = {
  leftText: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
};

ElementTable.default = {
  download: false,
};
