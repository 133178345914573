/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useContext } from 'react';
import propTypes from 'prop-types';
import Button from '../components/Button';
import { JWTContext } from '../App';

// eslint-disable-next-line react/prop-types
const CGV = ({ closePopup }) => {
  const context = useContext(JWTContext);
  const { JWTString } = context;

  const [RGPD, setRGPD] = useState('');
  const [cgvPDF, setcgvPDF] = useState('');
  const [lienRGPD, setlienRGPD] = useState('');
  const [checkOutil, setcheckOutil] = useState(false);
  const [checkCGV, setcheckCGV] = useState(false);
  const [checkRGPD, setcheckRGPD] = useState(false);
  const [lienCGV, setlienCGV] = useState('');
  const [choix, setchoix] = useState('1');
  const [accompagnement, setaccompagnement] = useState(false);
  const [choixAccompagnement, setchoixAccompagnement] = useState({});
  const [texteAccompagnement, settexteAccompagnement] = useState({});

  const [classNotActive, setclassNotActive] = useState('not-active');

  useEffect(() => {
    const getNumero = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/modulable/rgpd`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.lien_rgpd !== '' && res.lien_rgpd !== null) {
            setlienRGPD(res.lien_rgpd);
          } else {
            setRGPD(res.pdf_rgpd);
          }
          if (res.lien_cgv !== '' && res.lien_cgv !== null) {
            setlienCGV(res.lien_cgv);
          } else {
            setcgvPDF(res.pdf_cgv);
          }
        });
    };

    const accompagnementFunc = async () => {
      await fetch(
        `${process.env.REACT_APP_URL}back/api/1.0/modulable/accompagnement`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${JWTString}`,
          },
        }
      )
        .then((res) => res.json())
        .then(async (res) => {
          settexteAccompagnement(res);
          if (res.accompagnement === '1') {
            setaccompagnement(true);
            await fetch(
              `${process.env.REACT_APP_URL}back/api/1.0/modulable/accompagnementChoix`,
              {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${JWTString}`,
                },
              }
            )
              .then((acc) => acc.json())
              .then((acc) => {
                setchoixAccompagnement(acc);
              });
          }
        });
    };
    getNumero();
    accompagnementFunc();
  }, []);

  useEffect(() => {
    if (checkCGV && checkRGPD && checkOutil) {
      setclassNotActive('');
    } else {
      setclassNotActive('not-active');
    }
  }, [checkOutil, checkCGV, checkRGPD]);

  const downloadRGPD = () => {
    fetch(`${RGPD}`, {
      method: 'GET',
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'RGPD.pdf';
        element.click();
      });
  };

  const downloadCGV = () => {
    fetch(`${cgvPDF}`, {
      method: 'GET',
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'CGV.pdf';
        element.click();
      });
  };

  return (
    <>
      <h2>Vous êtes sur le point d&apos;utiliser notre outil de simulation</h2>
      {!accompagnement ? (
        <p>
          L&apos;utilisation de cet outil vous sera facturée sur la base
          d&apos;un forfait de{' '}
          <span className="bold">
            {texteAccompagnement.choix_1_accompagnement}
          </span>
          . <br />
        </p>
      ) : (
        ''
      )}{' '}
      <p style={{ display: 'none' }}>
        La convention collective nationale agricole du 15 septembre 2020
        s’applique à toutes les entreprises et exploitations de production
        agricole ainsi qu’aux CUMA.
      </p>
      {accompagnement ? (
        <>
          <p style={{ marginBottom: 0 }}>
            L&apos;utilisation de cet outil sera facturée selon le niveau
            d&apos;accompagnement que vous aurez choisi ci-dessous :
          </p>
          <br />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="choix1">
              <input
                id="choix1"
                name="forfait"
                type="radio"
                value="1"
                checked={choix === '1'}
                onChange={(event) => setchoix(event.target.value)}
              />
              {choixAccompagnement.choix_1_accompagnement}
            </label>
            <label htmlFor="choix2">
              <input
                id="choix2"
                name="forfait"
                type="radio"
                value="2"
                checked={choix === '2'}
                onChange={(event) => setchoix(event.target.value)}
              />
              {choixAccompagnement.choix_2_accompagnement}
            </label>
          </div>
          <br />
        </>
      ) : (
        ''
      )}
      <p>
        Nous vous invitons à consulter nos conditions générales sur notre site :{' '}
        <span
          onClick={() => {
            if (cgvPDF !== '') {
              downloadCGV();
            } else {
              window.open(
                lienCGV,
                '_blank' // <- This is what makes it open in a new window.
              );
            }
          }}
          style={{ color: '#32ba7c', cursor: 'pointer' }}
          className="bold"
        >
          <span className="bold">cliquez-ici</span>
        </span>
      </p>
      <p>
        Nous vous rappelons que{' '}
        <span className="bold">ne sont pas concernés</span> : les apprentis, les
        gérants et mandataires sociaux, les ETA, les entreprises de travaux
        forestiers, les centres équestres ainsi que les entreprises ostréicoles.
      </p>
      <p style={{ display: 'none' }} className="text-rgpd">
        Les informations recueillies sur ce site web font l’objet d’un
        traitement informatisé par Cerfrance Vendée tel qu’évoqué dans notre
        politique de protection des données. Celles-ci ne sont pas transmises à
        des tiers. Concernant vos droits, vous pouvez : • Vous opposer
        totalement à certains types de traitement de vos données, • Demander à
        modifier certaines de vos données (droit de rectification) • Demander à
        effacer l’intégralité de vos données (le droit à l’oubli ou à
        l’effacement) • Transférer vos données d’un opérateur à un autre (droit
        à la portabilité des données) Si vous souhaitez exercer vos droits,
        merci de bien vouloir nous envoyer un mail à l’adresse{' '}
        <span className="bold">rgpd@85.cerfrance.fr</span>
      </p>
      <div className="checkbox">
        <label htmlFor="checkbox-outils">
          <input
            onChange={() => {}}
            id="checkbox-outils"
            type="checkbox"
            checked={checkOutil}
            onClick={() => setcheckOutil(!checkOutil)}
          />
          J&apos;accepte les conditions générales et tarifaires
          d&apos;utilisation de l&apos;outil
        </label>
        <br />
        <label htmlFor="checkbox-cgv">
          <input
            onChange={() => {}}
            id="checkbox-cgv"
            type="checkbox"
            checked={checkCGV}
            onClick={() => setcheckCGV(!checkCGV)}
          />
          Je reconnais avoir pris connaissance des Conditions Générales de Vente
        </label>
        <br />
        <label htmlFor="checkbox-rgpd">
          <input
            onChange={() => {}}
            id="checkbox-rgpd"
            onClick={() => setcheckRGPD(!checkRGPD)}
            type="checkbox"
            checked={checkRGPD}
          />
          J’ai pris connaissance de mes responsabilités en matière de protection
          des données à caractère personnel (RGPD).
        </label>{' '}
        Pour plus d’informations,{' '}
        <span
          onClick={() => {
            if (RGPD !== '') {
              downloadRGPD();
            } else {
              window.open(
                lienRGPD,
                '_blank' // <- This is what makes it open in a new window.
              );
            }
          }}
          style={{ color: '#32ba7c', cursor: 'pointer' }}
          className="bold"
        >
          cliquez-ici
        </span>
        .
      </div>
      <div className="buttons-popup">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Button
          handleClick={closePopup}
          buttonClass="discover"
          type="white"
          value="Annuler"
        />
        <Button
          handleClick={closePopup}
          buttonClass={`start ${classNotActive}`}
          type="green"
          accompagnement={choix}
          value="Je continue"
          link="votre-entreprise"
        />
      </div>
    </>
  );
};

export default CGV;

CGV.prototype = {
  closePopup: propTypes.func.isRequired,
};
