/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import { useState, useEffect, useContext } from 'react';
import { JWTContext } from '../App';

const PopupEditEntreprise = ({
  inputValues,
  close,
  setModificationEntrepriseIsOK,
}) => {
  const [entreprise, setentreprise] = useState({});
  const context = useContext(JWTContext);
  const { JWTString } = context;

  const handleChange = (event) => {
    const { value } = event.target;
    setentreprise({
      ...entreprise,
      [event.target.name]: value,
    });
  };

  useEffect(() => {
    if (inputValues) {
      setentreprise(inputValues);
    }
  }, [inputValues]);

  const updateEntreprise = () => {
    const form = new FormData();
    form.append('nom', entreprise.name);
    form.append('dirigeants', entreprise.dirigeants);
    form.append('siege', entreprise.siege);
    form.append('siret', entreprise.siret);
    form.append('ape', entreprise.ape);

    fetch(`${process.env.REACT_APP_URL}back/api/1.0/entrepriseMod`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JWTString}`,
      },
      body: form,
    })
      .then((res) => res.text())
      .then((_res) => setModificationEntrepriseIsOK(true))
      .catch((err) => err);
  };

  const handleSubmit = (event) => {
    updateEntreprise();
    close();
    event.preventDefault();
  };

  return (
    <>
      <div className="title-form">
        <h3>Modifier les informations de l&apos;entreprise</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">
          Nom :
          <input
            required
            id="name"
            type="text"
            value={entreprise.name}
            name="name"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="dirigeants">
          Dirigeants :
          <input
            required
            id="dirigeants"
            type="text"
            value={entreprise.dirigeants}
            name="dirigeants"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="siege">
          Adresse :
          <input
            required
            id="siege"
            type="text"
            value={entreprise.siege}
            name="siege"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="siret">
          N° de SIRET :
          <input
            required
            id="siret"
            type="text"
            value={entreprise.siret}
            name="siret"
            onChange={handleChange}
          />
        </label>
        <label htmlFor="ape">
          Code APE / NAF :
          <input
            required
            id="ape"
            type="text"
            value={entreprise.ape}
            name="ape"
            onChange={handleChange}
          />
        </label>
        <input type="submit" value="Modifier les informations" />
      </form>
    </>
  );
};

export default PopupEditEntreprise;
