/* eslint-disable react/prop-types */
const PopupTerminer = ({ linkTwo }) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <h2 style={{ color: 'black', margin: '2em', marginBottom: '3em' }}>
        Toute l&apos;équipe Cerfrance vous remercie de votre confiance.
      </h2>
      <button
        onClick={() => {
          if (linkTwo) {
            window.location.href = linkTwo;
          }
        }}
        type="button"
        className="etape-validate"
      >
        Terminer
      </button>
    </div>
  );
};

export default PopupTerminer;
