/* eslint-disable react-hooks/exhaustive-deps */
import './Popup.css';
import propTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const Popup = ({ children, isShown, closePopup, addClass }) => {
  return (
    <div
      className="overlay"
      style={{ visibility: isShown ? 'visible' : 'hidden' }}
    >
      <div
        className={`modal ${addClass}`}
        style={{ opacity: isShown ? '1' : '0' }}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <button
          type="button"
          onClick={() => closePopup()}
          className="close-button"
        >
          ×
        </button>
        <div className="content-modal">{children}</div>
      </div>
    </div>
  );
};

export default Popup;

Popup.prototype = {
  isShown: propTypes.bool.isRequired,
  closePopup: propTypes.func.isRequired,
};

Popup.defaultProps = {
  addClass: '',
};
