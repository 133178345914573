/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { createContext, useState, useEffect, useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Calcul from './Calcul';
import Head from './Head';
import TopBar from './TopBar';
import Popup from '../components/Popup';
import PopupEmploi from './PopupEmploi';
import WarningPopup from './WarningPopup';
import { JWTContext } from '../App';

const Simulateur = () => {
  const context = useContext(JWTContext);
  const { JWTString } = context;

  const [reloadSalarie, setreloadSalarie] = useState(false);
  const [isShownPopupEmploi, setisShownPopupEmploi] = useState(false);
  const [CalculOK, setCalculOK] = useState([]);
  const [AlerteShown, setAlerteShown] = useState(false);
  const [oldSalarie, setoldSalarie] = useState({
    categorie: '',
    qualification: '',
    coefficient: '',
    salaire: '',
  });
  const [infosTopBar, setinfosTopBar] = useState([]);

  const location = useLocation();

  useEffect(() => {
    setisShownPopupEmploi(false);
    setreloadSalarie(false);
  }, []);

  useEffect(() => {
    const getAllSalaries = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/salaries`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          let ok = false;
          res.salaries.forEach(async (element) => {
            if (
              element.id !== location.idsal &&
              element.emploi === location.emploi
            ) {
              const form = new FormData();
              form.append('id', element.id);

              await fetch(
                `${process.env.REACT_APP_URL}back/api/1.0/calculSal`,
                {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    Authorization: `Bearer ${JWTString}`,
                  },
                  body: form,
                }
              )
                .then((data) => data.json())
                .then((data) => {
                  if (data && !ok) {
                    setisShownPopupEmploi(true);
                    ok = true;
                  }
                });
            }
          });
        });
    };

    const getSalarie = async () => {
      const form = new FormData();
      form.append('id', location.idsal);

      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/salarieName`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
        body: form,
      })
        .then((res) => res.json())
        .then((res) => {
          const fetch = oldSalarie;
          fetch.name = res.salaries.nom_prenom;
          fetch.idsal = res.salaries.id_salarie;
          fetch.categorie = res.salaries.categorie;
          fetch.qualification = res.salaries.qualification;
          fetch.coefficient = res.salaries.coef;
          fetch.salaire = res.salaries.salaire_brut;
          setinfosTopBar([res.salaries.nom_prenom, res.salaries.emploi]);
          setoldSalarie(fetch);
        });
    };

    if (location.idsal) {
      getSalarie();
      if (reloadSalarie === false) {
        getAllSalaries();
      }
    }
    // }
  }, [location, reloadSalarie]);

  const handleClickAlerte = () => {
    setAlerteShown(!AlerteShown);
  };

  const handleClickPopupEmploi = () => {
    setisShownPopupEmploi(!isShownPopupEmploi);
  };

  if (location.idsal) {
    return (
      <SimulateurContext.Provider
        value={{
          CalculOK,
          setCalculOK,
          location,
          oldSalarie,
          infosTopBar,
          setreloadSalarie,
        }}
      >
        <main id="div" className="notranslate">
          <TopBar name={infosTopBar[0]} emploi={infosTopBar[1]} />
          <Head
            showAlert={handleClickAlerte}
            oldSalarie={oldSalarie}
            idsal={location.idsal}
          />
          <Calcul />
          <Popup
            closePopup={handleClickAlerte}
            isShown={AlerteShown}
            addClass=""
          >
            <WarningPopup closePopup={handleClickAlerte} />
          </Popup>
          <Popup
            closePopup={handleClickPopupEmploi}
            isShown={isShownPopupEmploi}
            addClass=""
          >
            <PopupEmploi
              info={infosTopBar[1]}
              closePopup={handleClickPopupEmploi}
            />
          </Popup>
        </main>
      </SimulateurContext.Provider>
    );
  }
  return <Redirect to="/emploi" />;
};

export default Simulateur;
export const SimulateurContext = createContext();
