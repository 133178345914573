/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import { pdf } from '@react-pdf/renderer';
import Header from './Header';
import TableList from '../components/TableList';
import './Entreprise.css';
import Etape from '../components/Etape';
import AjoutSalarie from './AjoutSalarie';
import Popup from '../components/Popup';
import PopupAjout from './PopupAjout';
import PopupDelete from './PopupDelete';
import PopupEditEntreprise from './PopupEditEntreprise';
import { JWTContext } from '../App';
import Avenant from '../documents/Avenant';

const Entreprise = () => {
  const description =
    'Vérifiez et complétez si nécessaire les informations relatives à votre entreprise et à vos salariés.';

  const textEtape =
    'Vérifiez et complétez si nécessaire les informations relatives à votre entreprise et à vos salariés.';

  const contextJWT = useContext(JWTContext);
  const { JWTString } = contextJWT;

  const [isShownAjout, setisShownAjout] = useState(false);
  const [ModificationEntrepriseIsOK, setModificationEntrepriseIsOK] = useState(
    false
  );
  const [isShownEdit, setisShownEdit] = useState(false);
  const [isShownEditEntreprise, setisShownEditEntreprise] = useState(false);
  const [isShownDelete, setisShownDelete] = useState(false);
  const [inputValuesEntreprise, setinputValuesEntreprise] = useState({});
  const [inputValues, setinputValues] = useState({});
  const [nomEntreprise, setnomEntreprise] = useState('');
  const [fetchEntreprise, setfetchEntreprise] = useState([
    {
      leftText: 'Représenté par :',
      rightText: '',
      type: 'dirigeants',
    },
    {
      leftText: 'Dont le siège social est situé au :',
      rightText: '',
      type: 'siege',
    },
    {
      leftText: 'SIRET :',
      rightText: '',
      type: 'siret',
    },
    {
      leftText: 'APE / NAF :',
      rightText: '',
      type: 'ape',
    },
  ]);

  const [fetchSalaries, setfetchSalaries] = useState([]);
  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  useEffect(() => {
    const getEntreprise = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/entreprise`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const fetch = fetchEntreprise.map((data) => {
            setnomEntreprise(res.nom_entreprise);
            switch (data.type) {
              case 'dirigeants':
                data.rightText = res.dirigeants_entreprise;
                break;
              case 'siege':
                data.rightText = res.siege_social_entreprise;
                break;
              case 'siret':
                data.rightText = res.siret_entreprise;
                break;
              case 'ape':
                data.rightText = res.ape_entreprise;
                break;
              default:
                data.rightText = '';
                break;
            }
            return data;
          });
          setfetchEntreprise(fetch);
          setModificationEntrepriseIsOK(false);
        })
        .catch((err) => err);

      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/salaries`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const fetch = [];
          if (res.salaries.length >= 1) {
            res.salaries.forEach((element) => {
              fetch.push({
                title: element.nom_prenom,
                delete: true,
                edit: true,
                genre: element.genre,
                id_sal: element.id,
                table: [
                  {
                    leftText: 'Adresse',
                    rightText: element.adresse,
                    type: 'adresse',
                  },
                  {
                    leftText: 'Nationalité',
                    rightText: element.nationalite,
                    type: 'nationalite',
                  },
                  {
                    leftText: 'N°Sécurité Sociale',
                    rightText: element.num_securite_sociale,
                    type: 'num_ss',
                  },
                  {
                    leftText: 'Emploi',
                    rightText: element.emploi,
                    type: 'emploi-salarie',
                  },
                  {
                    leftText: 'Catégorie',
                    rightText: element.categorie,
                    type: 'categorie',
                  },
                  {
                    leftText: 'Qualification',
                    rightText: element.qualification,
                    type: 'qualif',
                  },
                  {
                    leftText: 'Salaire horaire Brut',
                    rightText: element.salaire_brut,
                    type: 'salaire',
                  },
                  {
                    leftText: 'Coefficient',
                    rightText: element.coef,
                    type: 'coef',
                  },
                ],
              });
            });
            setfetchSalaries(fetch);
          }
        })
        .catch((err) => err);
    };

    getEntreprise();
  }, [
    JWTString,
    isShownEdit,
    isShownAjout,
    isShownDelete,
    isShownEditEntreprise,
    ModificationEntrepriseIsOK,
  ]);

  const download = false;

  const handleClickAjout = () => {
    setisShownAjout(!isShownAjout);
  };

  const [idToDelete, setidToDelete] = useState('');
  const handleClickDelete = (idd) => {
    // eslint-disable-next-line radix
    if (Number.isInteger(parseInt(idd))) {
      setidToDelete(idd);
    }
    setisShownDelete(!isShownDelete);
  };

  const handleClickEditEntreprise = (title, idd, tableauElements) => {
    if (tableauElements) {
      let tab = {};

      if (tableauElements.length === 4) {
        tab = {
          name: title,
          dirigeants: tableauElements[0].rightText,
          siege: tableauElements[1].rightText,
          siret: tableauElements[2].rightText,
          ape: tableauElements[3].rightText,
        };
      }
      setinputValuesEntreprise(tab);
    }
    setisShownEditEntreprise(!isShownEditEntreprise);
  };

  const handleClickEdit = (title, idd, tableauElements, genre) => {
    if (tableauElements) {
      let tab = {};
      if (tableauElements.length === 8) {
        tab = {
          id: idd,
          nom: title,
          genre: genre,
          adresse: tableauElements[0].rightText,
          nationalite: tableauElements[1].rightText,
          ss: tableauElements[2].rightText,
          emploi: tableauElements[3].rightText,
          categorie: tableauElements[4].rightText,
          qualif: tableauElements[5].rightText,
          salaire: tableauElements[6].rightText,
          coef: tableauElements[7].rightText,
        };
      }

      setinputValues(tab);
    }

    setisShownEdit(!isShownEdit);
  };

  const ajout = true;

  const [ISok, setISok] = useState(false);
  useEffect(() => {
    const firstAvenantFIXINGPROBLEM = async () => {
      await delay(1_000);
      const props = {
        salarie: {
          adresse: 'gf',
          nom_prenom: 'hf',
          num_securite_sociale: 'fh',
          emploi: 'fgh',
        },
        calcul: {
          nouveau_coef_salarie: 'ghf',
          nouveau_palier_salarie: 'fh',
          nouveau_salaire_brut_salarie: 'hgf',
          nouvelle_categorie_salarie: 'hf',
        },
        name: 'fh',
        entreprise: {
          ape_entreprise: 'hf',
          dirigeants_entreprise: 'fh',
          nom_entreprise: 'hf',
          siege_social_entreprise: 'hfg',
          siret_entreprise: 'fh',
        },
        genreSal: 'male',
      };
      const doc = <Avenant {...props} />;
      const asPdf = pdf([]); // {} is important, throws without an argument
      asPdf.updateContainer(doc);
      // eslint-disable-next-line no-unused-vars
      const blob = await asPdf.toBlob();
    };
    if (!ISok) {
      setISok(true);
      firstAvenantFIXINGPROBLEM();
    }
  }, []);

  return (
    <>
      <Header
        etape="Etape 1/3"
        titre="Votre entreprise"
        description={description}
        tableau={fetchEntreprise}
        nomEntreprise={nomEntreprise}
        handleClickEdit={handleClickEditEntreprise}
      />
      <main className="main-entreprise">
        <section>
          <h2>Vos salariés</h2>
          <div className="salaries">
            <TableList
              download={download}
              handleClickEdit={handleClickEdit}
              handleClickDelete={handleClickDelete}
              list={fetchSalaries}
            />
            <AjoutSalarie handleClickAjout={handleClickAjout} />
          </div>
        </section>
        <Popup
          closePopup={handleClickAjout}
          isShown={isShownAjout}
          addClass="modal-je-decouvre"
        >
          <PopupAjout
            close={handleClickAjout}
            title="Ajouter un salarié"
            ajout={ajout}
          />
        </Popup>
        <Popup
          closePopup={handleClickEdit}
          isShown={isShownEdit}
          addClass="modal-je-decouvre"
        >
          <PopupAjout
            inputValues={inputValues}
            title="Modifier le salarié"
            ajout={!ajout}
            close={handleClickEdit}
          />
        </Popup>
        <Popup closePopup={handleClickDelete} isShown={isShownDelete}>
          <PopupDelete
            idToDelete={idToDelete}
            handleClickDelete={handleClickDelete}
          />
        </Popup>
        <Popup
          closePopup={handleClickEditEntreprise}
          isShown={isShownEditEntreprise}
        >
          <PopupEditEntreprise
            setModificationEntrepriseIsOK={setModificationEntrepriseIsOK}
            inputValues={inputValuesEntreprise}
            close={handleClickEditEntreprise}
          />
        </Popup>
      </main>
      <Etape
        numEtape="Etape 1/3 :"
        buttonTwo="Valider les informations"
        linkTwo="emploi"
        text={textEtape}
      />
    </>
  );
};

export default Entreprise;
