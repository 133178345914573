/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, createContext } from 'react';
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Home from './accueil/Home';
import Erreur from './components/Erreur';
import Emploi from './emploi/Emploi';
import Entreprise from './entreprise/Entreprise';
import Documents from './documents/Documents';
import Simulateur from './simulateur/Simulateur';
import Consommation from './backoffice/Consommation';
import Redirection from './accueil/Redirection';

export const JWTContext = createContext();
const App = () => {
  document.title = 'outil-ccna';

  const [logged, setlogged] = useState(false);
  const [visited, setvisited] = useState(false);
  const [isAuth, setisAuth] = useState(false);
  const [JWTString, setJWTString] = useState('');

  useEffect(() => {
    if (isAuth === false) {
      fetch(`${process.env.REACT_APP_URL}back/api/1.0/login`, {
        method: 'POST',
        credentials: 'include',
      })
        .then((res) => res.json())
        .then((res) => {
          setisAuth(true);
          setJWTString(res.jwt);
        })
        .catch((err) => err);
    }
  }, []);

  useEffect(() => {
    const getAuth = async () => {
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/auth`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setvisited(res);
        })
        .catch((err) => err);

      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/logged`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setlogged(res);
        })
        .catch((err) => err);
    };
    if (JWTString !== '') {
      getAuth();
    }
  }, [JWTString]);

  return (
    <ToastProvider>
      <JWTContext.Provider value={{ JWTString }}>
        <Router>
          {logged ? (
            <>
              {!visited ? (
                <Switch>
                  <Route exact path="/accueil" component={Home} />
                  <Route exact path="/usages" component={Consommation} />
                  <Route
                    path="*"
                    component={() => <Redirect to="/accueil" />}
                  />
                  <Route exact path="redirection" component={Redirection} />
                </Switch>
              ) : (
                <>
                  <Switch>
                    <Route
                      exact
                      path="/votre-entreprise"
                      component={Entreprise}
                    />
                    <Route exact path="/usages" component={Consommation} />
                    <Route
                      exact
                      path="/votre-entreprise"
                      component={Entreprise}
                    />
                    <Route exact path="/emploi" component={Emploi} />
                    <Route exact path="/vos-documents" component={Documents} />
                    <Route exact path="/simulateur" component={Simulateur} />
                    <Route
                      path="*"
                      component={() => <Redirect to="/votre-entreprise" />}
                    />
                  </Switch>
                </>
              )}
            </>
          ) : (
            <Switch>
              <Route
                exact
                path="/votre-entreprise"
                component={() => (
                  <Erreur texte="Vous n'êtes pas autorisé à visiter cette page, pour y accèder connectez vous à Cerfrance Connect" />
                )}
              />
              <Route
                exact
                path="/emploi"
                component={() => (
                  <Erreur texte="Vous n'êtes pas autorisé à visiter cette page, pour y accèder connectez vous à Cerfrance Connect" />
                )}
              />
              <Route
                exact
                path="/vos-documents"
                component={() => (
                  <Erreur texte="Vous n'êtes pas autorisé à visiter cette page, pour y accèder connectez vous à Cerfrance Connect" />
                )}
              />
              <Route
                exact
                path="/simulateur"
                component={() => (
                  <Erreur texte="Vous n'êtes pas autorisé à visiter cette page, pour y accèder connectez vous à Cerfrance Connect" />
                )}
              />
              <Route
                exact
                path="/"
                component={() => (
                  <Erreur texte="Vous n'êtes pas autorisé à visiter cette page, pour y accèder connectez vous à Cerfrance Connect" />
                )}
              />
              <Route exact path="/usages" component={Consommation} />
            </Switch>
          )}
        </Router>
      </JWTContext.Provider>
    </ToastProvider>
  );
};

export default App;
