/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

const CoutGlobal = ({ tableau, first, second, isShown }) => {
  const [SalaireBrut, setSalaireBrut] = useState('');
  const [SalaireNet, setSalaireNet] = useState('');
  const [CoutGlobalEmp, setCoutGlobalEmp] = useState('');

  const TabHoraireMensuel = [151.67, 173.33];
  const TauxMoyenCotisation = [
    { type: 'Ouvrier', sal: '22.59', pat: '36.13' },
    { type: 'Employé', sal: '22.59', pat: '36.13' },
    { type: 'Technicien', sal: '23.02', pat: '37.96' },
    { type: 'Agent de maîtrise', sal: '23.02', pat: '37.96' },
    { type: 'Cadre', sal: '23.02', pat: '37.96' },
  ];
  useEffect(() => {
    let hour = first;
    let cotSal = '';
    let cotPat = '';
    let horaireMensuel = '';
    let SMICFillon = '';
    let coefFillon = '';
    if (first === '0') {
      hour = second;
      horaireMensuel = (hour * 52) / 12;
      SMICFillon = horaireMensuel * 10.25;
    } else if (first === '35') {
      horaireMensuel = TabHoraireMensuel[0];
      SMICFillon = TabHoraireMensuel[0] * 10.25;
    } else if (first === '39') {
      horaireMensuel = TabHoraireMensuel[1];
      SMICFillon = 169 * 10.25;
    }

    const salaireMensuelBrut = tableau.salaire * horaireMensuel;
    TauxMoyenCotisation.forEach((data) => {
      if (data.type === tableau.categorie) {
        cotSal = data.sal / 100;
        cotPat = data.pat / 100;
      }
    });

    if (cotSal === '' && cotPat === '') {
      cotSal = '22.59';
      cotPat = '36.13';
    }

    coefFillon = (0.3026 / 0.6) * (1.6 * (SMICFillon / salaireMensuelBrut) - 1);

    const FillonTheo = salaireMensuelBrut * coefFillon;
    let FillonTheoRetenu = '';
    if (FillonTheo < 0) {
      FillonTheoRetenu = 0;
    } else {
      FillonTheoRetenu = FillonTheo;
    }

    const tauxCotSalRetenu = salaireMensuelBrut * cotSal;
    const tauxCotPatRetenuHorsFillon = salaireMensuelBrut * cotPat;
    let coutGlobalEmployeur =
      salaireMensuelBrut + tauxCotPatRetenuHorsFillon - FillonTheoRetenu;
    if (salaireMensuelBrut === 0) {
      coutGlobalEmployeur = 0;
    }
    const salaireNet = salaireMensuelBrut - tauxCotSalRetenu;

    setCoutGlobalEmp(Math.round(coutGlobalEmployeur));
    setSalaireNet(Math.round(salaireNet));
    setSalaireBrut(Math.round(salaireMensuelBrut));
  }, [first, second, tableau, isShown]);

  return (
    <div
      style={{
        backgroundColor: 'white',
        boxShadow:
          'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
        borderRadius: '0.25rem',
        padding: '0.75rem',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginTop: '0.5em',
      }}
    >
      <p style={{ margin: 0 }} className="element-case">
        Salaire brut mensuel : &nbsp;
        <span className="bold">{SalaireBrut} €</span>
      </p>
      <p style={{ margin: 0 }} className="element-case">
        Salaire net mensuel avant PAS : &nbsp;
        <span className="bold">{SalaireNet} €</span>
      </p>
      <p style={{ margin: 0 }} className="element-case">
        Coût global mensuel employeur : &nbsp;
        <span className="bold">{CoutGlobalEmp} €</span>
      </p>
    </div>
  );
};

export default CoutGlobal;
