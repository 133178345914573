/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../components/Button';
import './JeDecouvre.css';
import { JWTContext } from '../App';

// eslint-disable-next-line react/prop-types
const JeDecouvre = ({ handleClickCGV, closePopup, demoVideo }) => {
  const context = useContext(JWTContext);
  const { JWTString } = context;
  const [accompagnement, setaccompagnement] = useState(false);
  const [textesChoix, settextesChoix] = useState({ first: '', second: '' });

  useEffect(() => {
    const accompagnementFunc = async () => {
      await fetch(
        `${process.env.REACT_APP_URL}back/api/1.0/modulable/accompagnement`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${JWTString}`,
          },
        }
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.accompagnement === '1') {
            setaccompagnement(true);
          }
          settextesChoix({
            first: res.texte_1_accompagnement,
            second: res.texte_2_accompagnement,
          });
        });
    };
    accompagnementFunc();
  }, []);

  return (
    <>
      <div className="top">
        <h2>
          Repositionnez facilement vos salariés dans la nouvelle classification
          grâce à notre solution digitale !
        </h2>
        <section className="description">
          <p>
            L’entrée en application de cette nouvelle convention collective, et
            avec elle la nouvelle classification des emplois qu’elle instaure,
            vous impose de réaliser un certain nombre de démarches d’ici les
            paies du mois d’avril :
          </p>
          <p>
            - Informer vos salariés sur l’entrée en application de cette
            nouvelle convention collective et mettre un exemplaire à leur
            disposition, <br />- Évaluer tous vos emplois au regard de la
            nouvelle classification et « repositionner » chacun de vos salariés,
            <br />- Disposer et conserver les éléments justifiant les
            évaluations et positionnements que vous aurez définis, <br />-
            Informer par écrit chacun de vos salariés sur le nouveau
            positionnement de leur emploi et ses conséquences sur leurs
            salaires, <br />- Informer votre gestionnaire de paie pour mettre en
            conformité vos bulletins de salaire et DSN.
          </p>
          <p className="tarif">
            Les enjeux de cette démarche sont importants : évolution des
            salaires, changement de catégorie d’emploi, formalisme juridique à
            respecter…
          </p>
          <p>
            Il est donc essentiel que vous puissiez réaliser toutes ces
            démarches dans un cadre sécurisé, facilitant et qui vous prenne peu
            de temps.
          </p>
          <p>Notre solution vous permet en quelques clics seulement de :</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '1em', width: '30%' }}>
                <p
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ width: '5em', marginBottom: '1em' }}
                    src="img/1.png"
                    alt="icone"
                  />
                  <span className="bold">Faire l’évaluation</span> de tous vos
                  emplois de manière rapide et simple, sans que vous ayez besoin
                  de ressaisir vos données.
                </p>
              </div>
              <div style={{ padding: '1em', width: '30%' }}>
                <p
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ width: '5em', marginBottom: '1em' }}
                    src="img/2.png"
                    alt="icone"
                  />
                  <span className="bold">Simuler en direct les impacts</span>{' '}
                  sur les salaires et les cotisations pour votre entreprise.
                </p>
              </div>
              <div style={{ padding: '1em', width: '30%' }}>
                <p
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ width: '5em', marginBottom: '1em' }}
                    src="img/3.png"
                    alt="icone"
                  />
                  <span className="bold">Génerer tous les documents</span>{' '}
                  nécessaires pour communiquer et informer vos salariés
                  (synthèse des évaluations, affiches, courriers et avenants aux
                  contrats de travail pour chacun de vos salariés).
                </p>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '1em', width: '45%' }}>
                <p
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ width: '5em', marginBottom: '1em' }}
                    src="img/4.png"
                    alt="icone"
                  />
                  <span className="bold">Avoir la convention collective</span>{' '}
                  et ses annexes.
                </p>
              </div>
              <div style={{ padding: '1em', width: '45%' }}>
                <p
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ width: '5em', marginBottom: '1em' }}
                    src="img/5.png"
                    alt="icone"
                  />
                  Pour les clients du service paie Cerfrance : aucune donnée à
                  ressaisir et transmission automatique de tous les éléments à
                  votre gestionnaire de paie.
                </p>
              </div>
            </div>
          </div>
          {accompagnement && textesChoix.second !== '' ? (
            <>
              <p>{textesChoix.first}</p>
              <p>{textesChoix.second}</p>
            </>
          ) : textesChoix.first !== '' ? (
            <>{textesChoix.first}</>
          ) : (
            ''
          )}
          <p>Merci pour votre confiance.</p>
        </section>
      </div>

      <video
        id="video-demo"
        src={demoVideo}
        controls
        poster=""
        type="video/mp4"
      >
        Cette vidéo ne peut être affichée sur votre navigateur Internet.
        <br />
      </video>
      <section className="buttons buttons-je-decouvre">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Button
          handleClick={closePopup}
          buttonClass="discover"
          type="white"
          value="Retour"
        />
        <Button
          handleClick={handleClickCGV}
          closePopup={closePopup}
          buttonClass="start"
          type="green"
          value="Je démarre"
        />
      </section>
    </>
  );
};

export default JeDecouvre;

JeDecouvre.prototype = {
  handleClickCGV: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};
