import propTypes from 'prop-types';
import Button from '../components/Button';
import './Buttons.css';

// eslint-disable-next-line react/prop-types
const Buttons = ({ handleClickCGV, handleClickJeDecouvre }) => {
  return (
    <section className="buttons">
      <Button
        handleClick={handleClickJeDecouvre}
        buttonClass="discover"
        type="white"
        value="Je découvre"
      />
      <Button
        handleClick={handleClickCGV}
        buttonClass="start"
        type="green"
        value="Je démarre"
      />
    </section>
  );
};

export default Buttons;

Buttons.prototype = {
  handleClickCGV: propTypes.func.isRequired,
  handleClickJeDecouvre: propTypes.func.isRequired,
};
