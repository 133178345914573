/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useState, useEffect, useContext } from 'react';
import Popup from '../components/Popup';
import CalculColumn from './CalculColumn';
import InfoColumnPopup from './InfoColumnPopup';
// eslint-disable-next-line import/no-cycle
import { SimulateurContext } from './Simulateur';
import { JWTContext } from '../App';

const Calcul = () => {
  const contextSimulateur = useContext(SimulateurContext);
  const context = useContext(JWTContext);
  const { JWTString } = context;
  const { setCalculOK, location } = contextSimulateur;

  const technicite = [
    ['Déplacez le curseur pour selectionner une valeur', null],
    [
      "Exécution d’une ou plusieurs tâches <span class='bold'>simples</span>, nécessitant <span class='bold'>peu ou pas d’expérience</span>",
      2,
    ],
    [
      "Réalisation d’opérations <span class='bold'>qualifiées</span>, variées, nécessitant une <span class='bold'>expérience antérieure</span> ou un temps d’appropriation",
      4,
    ],
    [
      "Réalisation d’activités <span class='bold'>très qualifiées</span>, fortement évolutives, nécessitant une <span class='bold'>maîtrise confirmée du métier</span>",
      16,
    ],
    [
      "Capacité de conduite de l’ensemble des <span class='bold'>activités complexes</span> d’un domaine de spécialités, en nécessitant une <span class='bold'>maîtrise complète</span>",
      38,
    ],
    [
      "<span class='bold'>Responsabilité de l’ensemble des missions</span>, y compris <span class='bold'>expertes</span> d’un ou plusieurs domaines de spécialités",
      100,
    ],
  ];
  const autonomie = [
    ['Déplacez le curseur pour selectionner une valeur', null],
    [
      "<p>Application de <span class='bold'>consignes précises</span> ou de procédures connues, <span class='bold'>sous surveillance et contrôle fréquents</span></p>",
      2,
    ],
    [
      "<p>Activités définies par des <span class='bold'>instructions générales</span>, sous <span class='bold'>surveillance et contrôle intermittents</span> ou périodiques</p>",
      7,
    ],
    [
      "<p><span class='bold'>Adaptation des méthodes</span> dans le cadre d’objectifs définis ou à partir de directives générales, <span class='bold'>contrôle ou appréciation a posteriori</span> des résultats ou de l’atteinte des objectifs</p>",
      28,
    ],
    [
      "<p><span class='bold'>Définition de stratégies</span> par délégation directe, <span class='bold'>évaluation à moyen ou long terme</span> des résultats globaux de son périmètre de responsabilité</p>",
      100,
    ],
  ];

  const respect = [
    ['Déplacez le curseur pour selectionner une valeur', null],
    [
      "<p>Exigences normatives <span class='bold'>faibles ou simples</span> et conséquences <span class='bold'>limitées</span> en cas de non-respect</p>",
      1,
    ],
    [
      "<p>Exigences normatives fortes ou <span class='bold'>complexes</span> et conséquences <span class='bold'>importantes</span> en cas de non-respect</p>",
      4,
    ],
    [
      "<p><span class='bold'>Responsabilité</span> du déploiement des normes et de la vérification de leur respect</p>",
      25,
    ],
  ];

  const enjeux = [
    ['Déplacez le curseur pour selectionner une valeur', null],
    [
      "<p>Contribution <span class='bold'>limitée</span> aux objectifs de l’activité, obtenue notamment par le <span class='bold'>respect des contraintes</span> de productivité et de qualité</p>",
      1,
    ],
    [
      "<p>Contribution <span class='bold'>moyenne</span> au résultat de l’entreprise notamment par <span class='bold'>l’optimisation des moyens</span>, de l’organisation de son emploi/ de son équipe</p>",
      4,
    ],
    [
      "<p>Contribution <span class='bold'>essentielle</span> au développement de l’entreprise notamment par <span class='bold'>orientation de ses activités</span> et identification d’innovations</p>",
      25,
    ],
  ];

  const management = [
    ['Déplacez le curseur pour selectionner une valeur', null],
    [
      "<p><span class='bold'>Transmission de savoir-faire</span> possible et/ou rappeler une consigne. <span class='bold'>Pas de responsabilité particulière d’animation</span>.</p>",
      2,
    ],
    [
      "<p><span class='bold'>Animation technique et/ou organisationnelle régulière</span> d’une ou plusieurs équipes, majoritairement <span class='bold'>temporaires/saisonniers</span></p>",
      3,
    ],
    [
      "<p><span class='bold'>Animation technique et/ou organisationnelle</span> d’une ou plusieurs équipes majoritairement <span class='bold'>permanente</span></p>",
      10,
    ],
    [
      "<p><span class='bold'>Encadrement hiérarchique</span> de proximité (d’une ou plusieurs équipes d’exécution)</p>",
      23,
    ],
    [
      "<p><span class='bold'>Encadrement hiérarchique d’encadrants</span> ou de cadres</p>",
      48,
    ],
    [
      "<p><span class='bold'>Responsabilité hiérarchique de tous les collaborateurs</span> de l’entreprise ou de l’établissement</p>",
      100,
    ],
  ];

  const relationnel = [
    ['Déplacez le curseur pour selectionner une valeur', null],
    [
      "<p>Echanges professionnels <span class='bold'>simples</span>, nécessitant de bien comprendre ses Interlocuteurs internes ou externes et de se faire comprendre par eux</p>",
      1,
    ],
    [
      "<p>Echanges professionnels <span class='bold'>courants</span>, nécessitant une écoute attentive de ses interlocuteurs, la <span class='bold'>reformulation de leurs demandes</span>, une <span class='bold'>force de conviction et l’apport de conseils</span></p>",
      4,
    ],
    [
      "<p>Echanges professionnels <span class='bold'>complexes</span>, nécessitant la <span class='bold'>construction d’argumentaires et d’explications pédagogiques</span>, la capacité à mobiliser ses interlocuteurs externes ou internes</p>",
      14,
    ],
    [
      "<p><span class='bold'>Relationnel professionnel déterminant</span> pour l’entreprise, nécessitant <span class='bold'>persuasion et diplomatie, conduite de négociations et représentation de l’entreprise</span> auprès d’acteurs privés ou institutionnels</p>",
      50,
    ],
  ];

  const [techniciteOK, settechniciteOK] = useState(0);
  const [autonomieOK, setautonomieOK] = useState(0);
  const [respectOK, setrespectOK] = useState(0);
  const [enjeuxOK, setenjeuxOK] = useState(0);
  const [managementOK, setmanagementOK] = useState(0);
  const [relationnelOK, setrelationnelOK] = useState(0);

  useEffect(() => {
    const getCalcul = async () => {
      const form = new FormData();
      form.append('id', location.idsal);
      await fetch(`${process.env.REACT_APP_URL}back/api/1.0/calculSal`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${JWTString}`,
        },
        body: form,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.json_calcul) {
            const tabCalcul = JSON.parse(res.json_calcul);
            settechniciteOK(parseInt(tabCalcul[0]));
            setautonomieOK(parseInt(tabCalcul[1]));
            setrespectOK(parseInt(tabCalcul[2]));
            setenjeuxOK(parseInt(tabCalcul[3]));
            setmanagementOK(parseInt(tabCalcul[4]));
            setrelationnelOK(parseInt(tabCalcul[5]));
          }
        });
    };

    getCalcul();
  }, []);

  useEffect(() => {
    if (
      techniciteOK > 0 &&
      autonomieOK > 0 &&
      respectOK > 0 &&
      enjeuxOK > 0 &&
      managementOK > 0 &&
      relationnelOK > 0
    ) {
      setCalculOK([
        techniciteOK,
        autonomieOK,
        respectOK,
        enjeuxOK,
        managementOK,
        relationnelOK,
      ]);
    } else {
      setCalculOK([]);
    }
  }, [
    techniciteOK,
    autonomieOK,
    respectOK,
    enjeuxOK,
    managementOK,
    relationnelOK,
    setCalculOK,
  ]);

  const [showInfoColumn, setshowInfoColumn] = useState('');
  const [isShownInfo, setisShownInfo] = useState(false);

  const showInfoPopup = (type) => {
    if (showInfoColumn === '') {
      setshowInfoColumn(type);
    } else {
      setshowInfoColumn('');
    }
    setisShownInfo(!isShownInfo);
  };

  return (
    <section className="calcul">
      <CalculColumn
        name="Technicité"
        type="technicite"
        tableau={technicite}
        isOK={settechniciteOK}
        showInfoPopup={showInfoPopup}
        current={techniciteOK}
      />
      <CalculColumn
        name="Autonomie"
        type="autonomie"
        tableau={autonomie}
        isOK={setautonomieOK}
        showInfoPopup={showInfoPopup}
        current={autonomieOK}
      />
      <CalculColumn
        name="Responsabilité"
        type="responsabilite"
        subName="Respect des normes"
        tableau={respect}
        isOK={setrespectOK}
        showInfoPopup={showInfoPopup}
        current={respectOK}
      />
      <CalculColumn
        name="Responsabilité"
        type="responsabilite"
        subName="Enjeux économiques"
        tableau={enjeux}
        isOK={setenjeuxOK}
        showInfoPopup={showInfoPopup}
        current={enjeuxOK}
      />
      <CalculColumn
        name="Management"
        type="management"
        tableau={management}
        isOK={setmanagementOK}
        showInfoPopup={showInfoPopup}
        current={managementOK}
      />
      <CalculColumn
        name="Relationnel"
        type="relationnel"
        tableau={relationnel}
        isOK={setrelationnelOK}
        showInfoPopup={showInfoPopup}
        current={relationnelOK}
      />
      <Popup closePopup={showInfoPopup} isShown={isShownInfo} addClass="">
        <InfoColumnPopup type={showInfoColumn} />
      </Popup>
    </section>
  );
};

export default Calcul;
