import './Content.css';

// eslint-disable-next-line react/prop-types
const Content = ({ handleClickVideo }) => {
  return (
    <>
      <button
        onClick={() => {
          handleClickVideo();
        }}
        className="button-video"
        type="button"
        href="#video"
      >
        Voir la vidéo de présentation
      </button>
      <h1 id="title">Nouvelle Convention Collective Nationale Agricole </h1>
      <h2 id="sub-title">
        Repositionnez facilement vos emplois dans la nouvelle classification
        grâce à notre solution digitale !
      </h2>
      <section className="description">
        <p>
          La nouvelle convention collective nationale agricole entre en
          application au
          <span className="bold"> 1er avril prochain</span>.
        </p>
        <p>
          Parmi les principaux changements, cette convention collective instaure
          une nouvelle classification des emplois et des compétences au niveau
          national, unique et commune à toutes les entreprises du secteur
          agricole.
        </p>
        <p>
          Cette nouvelle classification nécessite entre autre, que vous
          procédiez à l’évaluation de tous les emplois présents au sein de votre
          entreprise sur la base des nouveaux critères prédéfinis, et au
          repositionnement de chacun de vos salariés.{' '}
        </p>
        <p>
          Cette nouvelle convention collective concerne l’ensemble des
          entreprises de la production agricole et les CUMA.
          <span className="bold"> Ne sont pas concernés</span> : les ETA, les
          entreprises de travaux forestiers, les centres équestres et les
          entreprises conchylicoles. Les apprentis et les dirigeants ne sont pas
          concernés par cette nouvelle classification.
        </p>
        <p>
          Pour vous faciliter les démarches, nous avons développé{' '}
          <span className="bold">
            une solution simple qui vous permet en quelques clics seulement, de
            repositionner tous vos emplois et de générer pour chacun de vos
            salariés les documents et contrats nécessaires.
          </span>
        </p>
      </section>
      <br />
    </>
  );
};

export default Content;
