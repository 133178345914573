/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/button-has-type */
/* eslint-disable no-proto */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import { useState, useEffect, useContext, useRef } from 'react';
import { CSVLink } from 'react-csv';
import {
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactExport from 'react-export-excel';
import { DataContext } from './BackOffice';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const Choice = () => {
  const context = useContext(DataContext);
  const [entrepriseData, setentrepriseData] = useState('');
  const [salariesData, setsalariesData] = useState('');

  const {
    selectedDate,
    setSelectedDate,
    Code,
    setCode,
    jwt,
    listCode,
  } = context;

  const handleChangeCode = (event) => {
    const { value } = event.target;
    setCode(value);
  };

  useEffect(() => {
    if (Code === '' && listCode.length > 0) {
      setCode(listCode[0]);
    }
  }, [listCode]);

  const handleChangeDate = (event) => {
    const { value } = event.target;
    setSelectedDate({ ...selectedDate, [event.target.name]: value });
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  // eslint-disable-next-line no-unused-vars
  const [classes, setclasses] = useState(useStyles());
  const entrepriseButton = useRef();
  const salarieButton = useRef();

  const getAsyncDataEntreprise = async () => {
    const form = new FormData();
    form.append('code', Code);
    let data = [];
    await fetch(
      `${process.env.REACT_APP_URL}backoffice/api/1.0/entrepriseCSV`,
      {
        method: 'POST',
        body: form,
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setentrepriseData(res);
        data = res;
      })
      .catch((err) => err);
    return data;
  };

  const getAsyncDataSalarie = async () => {
    const form = new FormData();
    form.append('code', Code);
    let data = [];
    await fetch(`${process.env.REACT_APP_URL}backoffice/api/1.0/salariesCSV`, {
      method: 'POST',
      body: form,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setsalariesData(res);
        data = res;
      })
      .catch((err) => err);
    return data;
  };

  function hasOwnProperty(obj, prop) {
    const proto = obj.__proto__ || obj.constructor.prototype;
    return prop in obj && (!(prop in proto) || proto[prop] !== obj[prop]);
  }

  useEffect(() => {
    if (
      entrepriseData.length &&
      entrepriseButton.current &&
      entrepriseButton.current.link
    ) {
      setTimeout(() => {
        if (
          entrepriseButton.current !== null &&
          hasOwnProperty(entrepriseButton.current, 'link')
        ) {
          entrepriseButton.current.link.dataset.interception = 'off';
          entrepriseButton.current.link.click();
        }
        setentrepriseData([]);
      });
    }

    if (
      salariesData.length > 0 &&
      salarieButton.current &&
      salarieButton.current.link
    ) {
      setTimeout(() => {
        if (
          salarieButton.current !== null &&
          hasOwnProperty(salarieButton.current, 'link')
        ) {
          salarieButton.current.link.dataset.interception = 'off';
          salarieButton.current.link.click();
        }
        setsalariesData([]);
      });
    }
  }, [entrepriseData, salariesData]);

  const refEntrepriseExcel = useRef();
  const refSalarieExcel = useRef();
  return (
    <div className="choix-date">
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">
          Code cerfrance
        </InputLabel>
        <Select
          native
          value={Code}
          onChange={handleChangeCode}
          label="Age"
          inputProps={{
            name: 'Code cerfrance',
            id: 'outlined-age-native-simple',
          }}
        >
          {listCode.map((el) => {
            return (
              <option key={el} value={el}>
                {el}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <div className="field-date">
        <p>Du</p>
        <TextField
          id="date"
          type="date"
          className="date-pick"
          name="debut"
          value={selectedDate.debut}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChangeDate}
        />
      </div>
      <div className="field-date">
        <p>Au</p>
        <TextField
          id="date"
          type="date"
          className="date-pick"
          name="fin"
          value={selectedDate.fin}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChangeDate}
        />
      </div>
      <Button
        onClick={async () => {
          const res = await getAsyncDataEntreprise();
          if (res.length > 0) {
            refEntrepriseExcel.current.click();
          }
        }}
        id="export-csv-entreprise"
        variant="contained"
      >
        Télecharger les entreprises
      </Button>
      <ExcelFile
        filename={`Entreprises_${Code}`}
        element={
          <Button
            ref={refEntrepriseExcel}
            style={{ display: 'none' }}
            variant="contained"
          >
            Télecharger les entreprises
          </Button>
        }
      >
        {entrepriseData.length > 0 ? (
          <ExcelSheet data={entrepriseData} name={`Entreprises_${Code}`}>
            <ExcelColumn label="Code Cerfrance" value="code_cerfrance" />
            <ExcelColumn label="Numéro dossier" value="dossier" />
            <ExcelColumn label="Dirigeants" value="dirigeants_entreprise" />
            <ExcelColumn label="Raison sociale" value="nom_entreprise" />
            <ExcelColumn label="APE" value="ape_entreprise" />
            <ExcelColumn label="SIRET" value="siret_entreprise" />
            <ExcelColumn label="Adresse 1" value="siege_social_entreprise" />
            <ExcelColumn
              label="Adresse 2"
              value="siege_social_entreprise_bis"
            />
            <ExcelColumn
              label="Gestionnaire paie"
              value="gestionnaire_entreprise"
            />
            <ExcelColumn
              label="Adresse mail gestionnaire"
              value="email_gestionnaire_entreprise"
            />
            <ExcelColumn
              label="Date/heure - Validation des CGV/RGPD/Tarifs"
              value="date_demarrage_utilisation"
            />
            <ExcelColumn
              label="Date/heure - 1er salarié repositionné"
              value="premier_calcul_salarie"
            />
            <ExcelColumn
              label="Info. entreprise modifié ?"
              value="informations_entreprise_modifie"
            />
            <ExcelColumn
              label="Accompagnement? (0=autonome)"
              value="demande_accompagnement"
            />
          </ExcelSheet>
        ) : (
          ' '
        )}
      </ExcelFile>
      <Button
        id="export-csv-salaries"
        onClick={async () => {
          const res = await getAsyncDataSalarie();
          if (res.length > 0) {
            refSalarieExcel.current.click();
          }
        }}
        variant="contained"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          Salariés et calculs{' '}
          <span style={{ fontFamily: 'Nexa', textTransform: 'lowercase' }}>
            <span style={{ textTransform: 'capitalize' }}>Attention</span>, il
            peut y avoir plusieurs évaluations pour un salarié et donc plusieurs
            lignes pour 1 salarié
          </span>
        </span>
      </Button>
      <ExcelFile
        filename={`Salaries_${Code}`}
        element={
          <Button
            ref={refSalarieExcel}
            style={{ display: 'none' }}
            variant="contained"
          >
            Télecharger les salariés
          </Button>
        }
      >
        {salariesData.length > 0 ? (
          <ExcelSheet data={salariesData} name={`Salaries_${Code}`}>
            <ExcelColumn label="ID unique salarié" value="id_salarie" />
            <ExcelColumn label="Code Cerfrance" value="code_cerfrance" />
            <ExcelColumn label="Nom prénom" value="nom_prenom_salarie" />
            <ExcelColumn label="Numéro salarié" value="num_dossier_salarie" />
            <ExcelColumn
              label="Numéro dossier"
              value="num_dossier_entreprise"
            />
            <ExcelColumn label="Date naissance" value="date_naissance" />
            <ExcelColumn label="Genre salarié" value="genre_salarie" />
            <ExcelColumn label="Adresse 1" value="adresse_salarie" />
            <ExcelColumn label="Adresse 2" value="adresse_salarie_bis" />
            <ExcelColumn label="Nationalité" value="nationalite_salarie" />
            <ExcelColumn label="Numéro sécu" value="num_securite_sociale" />
            <ExcelColumn label="Emploi" value="emploi_actuel" />
            <ExcelColumn
              label="Catégorie avant positionnement"
              value="categorie_actuelle"
            />
            <ExcelColumn
              label="Qualification actuelle"
              value="qualification_actuelle"
            />
            <ExcelColumn
              label="Salaire horaire avant"
              value="salaire_horaire_brut_actuel"
            />
            <ExcelColumn
              label="Date/heure ajout d'un salarié"
              value="date_ajout_salarie"
            />
            <ExcelColumn
              label="Salarié supprimé (1=supprimé)"
              value="salarie_supprime"
            />
            <ExcelColumn
              label="Salarié modifié (1=modifié)"
              value="salarie_modifie"
            />
            <ExcelColumn
              label="Catégorie après positionnement"
              value="nouvelle_categorie_salarie"
            />
            <ExcelColumn
              label="Nouveau palier"
              value="nouveau_palier_salarie"
            />
            <ExcelColumn
              label="Nouveau coefficient"
              value="nouveau_coef_salarie"
            />
            <ExcelColumn
              label="Nouveau salaire horaire"
              value="nouveau_salaire_brut_salarie"
            />
            <ExcelColumn
              label="Date/heure positionnement"
              value="date_calcul"
            />
          </ExcelSheet>
        ) : (
          ' '
        )}
      </ExcelFile>
    </div>
  );
};

export default Choice;
