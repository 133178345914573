// eslint-disable-next-line react/prop-types
const InfoColumnPopup = ({ type }) => {
  const downloadFile = async () => {
    await fetch('Grille_de_classification_des_emplois.pdf', {
      method: 'GET',
    })
      .then((res) => res.blob())
      .then((res) => {
        const element = document.createElement('a');
        const file = new Blob([res], {
          type: 'application/pdf;charset=utf-8',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'Grille_de_classification_des_emplois.pdf';
        element.click();
      });
  };
  return (
    <div className="info-column-popup">
      <h4>
        Sélectionner la phrase correspondant aux attendus de l’emploi évalué. Si
        l’affirmation ne correspond pas totalement au poste, vous devez alors
        retenir le degré inférieur.
      </h4>
      <img className="img-modal" src={`img/${type}.png`} alt={type} />
      <button
        onClick={() => downloadFile()}
        className="download-position"
        type="button"
      >
        Télécharger le document complet
      </button>
    </div>
  );
};

export default InfoColumnPopup;
