/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import { useContext, useEffect, useState } from 'react';
import { EntrepriseContext } from './Documents';

const EmailPopup = ({ handleClickEmail }) => {
  const entrepriseContext = useContext(EntrepriseContext);
  const [chargement, setchargement] = useState(false);

  const { entreprise } = entrepriseContext;
  // ({entreprise.email_gestionnaire_entreprise})
  const strUcFirst = (a) => {
    return `${a}`.charAt(0).toUpperCase() + a.substr(1);
  };

  const [nomGestionnaire, setnomGestionnaire] = useState('');
  useEffect(() => {
    if (entreprise.gestionnaire_entreprise) {
      const name = entreprise.gestionnaire_entreprise.split(' ');
      setnomGestionnaire(
        `${strUcFirst(name[1].toLowerCase())} ${strUcFirst(
          name[0].toLowerCase()
        )}`
      );
    }
  }, [entreprise]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <h2>Envoyer l&apos;évaluation à votre gestionnaire de paye</h2>
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.1em',
            marginBottom: '3em',
          }}
        >
          Cliquer sur le bouton &quot;Envoyer l&apos;évaluation&quot; pour
          informer{' '}
          <span style={{ fontFamily: 'Nexa-bold' }}>{nomGestionnaire}</span>
        </p>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          <button
            onClick={() => handleClickEmail()}
            style={{
              boxShadow:
                'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
              display: 'flex',
              alignItems: 'center',
              height: '80%',
              justifyContent: 'center',
              padding: '0.75rem 2rem',
              border: 'solid 1px transparent',
              borderRadius: '0.25rem',
              color: 'black',
              backgroundColor: 'white',
              textDecoration: 'none',
              fontFamily: 'Nexa-Light',
            }}
            type="button"
          >
            Annuler
          </button>
          <button
            onClick={async () => {
              setchargement(true);
              const ok = await handleClickEmail(
                entreprise.email_gestionnaire_entreprise
              );
              if (ok) {
                setchargement(false);
              }
            }}
            style={{
              boxShadow:
                'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)',
              display: 'flex',
              alignItems: 'center',
              height: '80%',
              justifyContent: 'center',
              padding: '0.75rem 2rem',
              border: 'solid 1px transparent',
              borderRadius: '0.25rem',
              color: 'white',
              backgroundColor: '#18a096',
              textDecoration: 'none',
              fontFamily: 'Nexa-light',
              marginLeft: '1em',
            }}
            type="button"
          >
            Envoyer l&apos;évaluation
          </button>
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              display: chargement ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'black',
              fontSize: '1.2em',
              height: '100%',
            }}
          >
            <img width="40em" src="img/SmallLoader.gif" alt="chargement" />
            Envoi en cours ...
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailPopup;
